const data = {
  name: 'Awards',
  variations: [
    {
      title: '',
      props: {
        data: {
          items: [
            {
              award_company: {
                html: '<p>Westpac</p>',
                text: 'Westpac',
              },
              award_name: {
                html: '<p>Businesses of Tomorrow 2018</p>',
                text: 'Businesses of Tomorrow 2018',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%2BAward.png?auto=compress%2Cformat&rect=0%2C0%2C206%2C178&w=2000&h=1728',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAIAAABSJhvpAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/ElEQVQ4y3WT726aUBjGudeuuwl3BXW34BqT2n1QCepBLYmYDqJEnU4TQhAiYNEQAYMVKQXxLMJ2RtH9PpzwvsnDed4/BzuliKLo+JcoilAmikFJBJYOYIp05vI7K4YQbrfbl5jlcuk4jud5lmWtViszRtf13W6X1mPIMIRwPpcKhUKnQwMAhsOhbduSJN/efs7lvuRyuZubT6qqQQiR+Q/i0ejn4+N3hmFomhZF0TRNz/OKxWI+//XuLn9//+2/NYfhuU+oMAjh8XjMNCJTNoauVVW13X5SFMVxnPV6PZ/PN5uNbduGYdj21rIs0zQty/Z9H+n/iQVBoCiKIAiSJB8eHlqtVqVSKZfLSbJUKgEASJLkeR6VjaFWu66rKKqqqpIkv77uDwfP8w77/d6Lcd2D6x58309K+3MzhOdqDcMYDAbT6VSWZYqidF2XZVkQBE3TlsulKIrSGVlRlNlsZppmoscghG9vfr/fr9Vq8Tgtmu4+P/8gCAIAgOP40xmK4ziKorrdbrVaXSwWiXMs8RyGwft7EIbnBQzDEBlLFhOFyaYmU8jO2XEcAMhOp1Ov1yeTSbPZbMfgON5ogHq9EQTBlTkjMcuyPM/3ej1N0ziOm0x+jcdjlmUZhhmNRkEQXFnPy4dxldNHsEycvDt0ZrgqzvwSpr2cLkD7+xtuXqpijPqUoAAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/cad36a7f111c0bc3df0783e7db17da22/c8708/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.png',
                          srcSet:
                            '/static/cad36a7f111c0bc3df0783e7db17da22/082db/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.png 500w,\n/static/cad36a7f111c0bc3df0783e7db17da22/22d10/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.png 1000w,\n/static/cad36a7f111c0bc3df0783e7db17da22/c8708/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/cad36a7f111c0bc3df0783e7db17da22/96efd/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.webp 500w,\n/static/cad36a7f111c0bc3df0783e7db17da22/692db/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.webp 1000w,\n/static/cad36a7f111c0bc3df0783e7db17da22/ded7b/4163cf98-9fa4-47bb-9f53-c3a6af2fd94d_ca7796c0-9736-4258-b19d-a3e667ffa6c2_Westpac%252BAward.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1728,
                    },
                  },
                },
              },
            },
            {
              award_company: {
                html: '<p>Good Design</p>',
                text: 'Good Design',
              },
              award_name: {
                html: '<p>Award of the Year</p>',
                text: 'Award of the Year',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%2BDesign%2BAward%2B1.png?auto=compress%2Cformat&rect=0%2C0%2C210%2C84&w=2000&h=800',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABRUlEQVQoz03SQUuUURTG8dda6hdo9d7fDBPNbFpIQRAtWgRDi8DMhZWRZMxgUgQqLqJoIWotgkCpnRAFbiJw0xcKXGjWrjKLE2diFg/nvu8993/Ofc6t8Cv1M+NX7GC80WhUIdzGU8xhGQ+wgo3cO9FsNiOvigPHqT+po4h4l7DQBF5iBvcz3sUSrg0Kh6oBCL+jy1x/6nQ6ATpf1/UIruIRHuNmQqcwn989nPsHxBccYADezc4i6bCUMorrWMdb3MIqnuNZWvAB/QHwTbvdjriJzwm7l/D9UsrJqI47mMaNXF/OjhfDx1JKt67rsSoPrg0NoDdkw8f8N4vt1FYWfxXN4DUWcPG/h3FdvAhPhmB7OJPAS0O+hRWT6V03BxSdnsbZSP6Ob/iRTydA79GKCbdarci5gic4hQt4mEMKSD+LxdVX/wKnBYo0lFYXOgAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/b61a36c35b2458f6e801d1965ed839f6/bd0ed/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.png',
                          srcSet:
                            '/static/b61a36c35b2458f6e801d1965ed839f6/5346e/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.png 500w,\n/static/b61a36c35b2458f6e801d1965ed839f6/a3c57/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.png 1000w,\n/static/b61a36c35b2458f6e801d1965ed839f6/bd0ed/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/b61a36c35b2458f6e801d1965ed839f6/6686a/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.webp 500w,\n/static/b61a36c35b2458f6e801d1965ed839f6/71a92/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.webp 1000w,\n/static/b61a36c35b2458f6e801d1965ed839f6/051cb/a6902107-48e8-4430-96c7-53c2add344f3_d51069f8-afd4-4634-ae12-09cba05dfc97_Good%252BDesign%252BAward%252B1.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 800,
                    },
                  },
                },
              },
            },
            {
              award_company: {
                html: '<p>Good Design</p>',
                text: 'Good Design',
              },
              award_name: {
                html: '<p>Best in Class</p>',
                text: 'Best in Class',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%2BDesign%2BAward%2B2.png?auto=compress%2Cformat&rect=0%2C0%2C210%2C80&w=2000&h=762',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPklEQVQoz03RvU5UYRDG8YWEEHoTC9mT83s7q70CJWtjYiEFhCAmGCgQNFogBjUUfAUokIIGGmIoiVfgRRhuwJ6CGwAlrJnNnGSLyfuRmWf+80wLfzNu8Q9XOMI4WnVdj2AVn/EOX/ABW9jHi8jDcCml1Sql3Gf0Bu9ZEIljeIsDvML7gfMTJrJx5PYFeylwF4R5P+t0OpHQia54k3QfMYdFzGIpxV/jUV8Q17gZED5PkT1cdrvduK/gBLtJ+w2nWE57fuJJI7hdVdUofuEixb4iGvzO98skCdLJJHyGjYa6ruunVVUNR3JQLURhFm+kWPwf5d96kGd8xw8chjXYwRoeN0vppehSbrIR+4MH7XZ7CDPp4XwSPc9xu0kb74foU90NbDmEbmL0xmSMpgW7STJZSgm/jjEV02E6yTf/A3t0igay+wc+AAAAAElFTkSuQmCC',
                      },
                      images: {
                        fallback: {
                          src: '/static/5b0f00941e3a5c15e11ba7025ad3af22/799b4/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.png',
                          srcSet:
                            '/static/5b0f00941e3a5c15e11ba7025ad3af22/b11de/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.png 500w,\n/static/5b0f00941e3a5c15e11ba7025ad3af22/634f6/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.png 1000w,\n/static/5b0f00941e3a5c15e11ba7025ad3af22/799b4/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5b0f00941e3a5c15e11ba7025ad3af22/07fd4/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.webp 500w,\n/static/5b0f00941e3a5c15e11ba7025ad3af22/d31db/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.webp 1000w,\n/static/5b0f00941e3a5c15e11ba7025ad3af22/061f3/b042c9ea-bee6-4f3a-abb2-ac656c4b3345_e5c7dbba-679c-45c9-b715-a7eb23d49961_Good%252BDesign%252BAward%252B2.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 762,
                    },
                  },
                },
              },
            },
          ],
          primary: {
            content: {
              html: '<p>Our printer is not only functional, but beautiful as well. We’ve been fortunate enough to win awards for its design.</p>',
              text: 'Our printer is not only functional, but beautiful as well. We’ve been fortunate enough to win awards for its design.',
            },
            title: {
              html: '<h4>Awards</h4>',
              text: 'Awards',
            },
          },
        },
      },
    },
  ],
};

export default data;
