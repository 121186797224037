const data = {
  name: 'Three Column Images',
  variations: [
    {
      title: '',
      props: {
        data: {
          items: [
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.jpeg?auto=compress%2Cformat&rect=0%2C0%2C830%2C554&w=2000&h=1335',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAYHAv/EABYBAQEBAAAAAAAAAAAAAAAAAAQCBf/aAAwDAQACEAMQAAABuCGi5S+5kwLzP//EABwQAAICAgMAAAAAAAAAAAAAAAIEAwUBBhIUFf/aAAgBAQABBQIOq0sjiBV4mAI9Z4spxUsxnSW3mp//xAAbEQACAwADAAAAAAAAAAAAAAABAgAEESKBwf/aAAgBAwEBPwGlSFreWdb6I6FWIE//xAAbEQACAQUAAAAAAAAAAAAAAAAAAgEDESEiMf/aAAgBAgEBPwFdukVFtk//xAAiEAACAgICAAcAAAAAAAAAAAABAgMRABIEMQUTIiRSgbH/2gAIAQEABj8CEsHJ3U9FejkAlYGbjw6Myyn8+8IjffT0tfd54kEQQskI0Is6m+xj+/l8z51jQNEJWEjXJdbZ/8QAHRAAAgMAAgMAAAAAAAAAAAAAAREAITFBcVHB8f/aAAgBAQABPyGjxlxlxPuAxrFCLRHH4gpjmFTZB8GxGgjFsyqJ2A+rzY737h0C5WnpCNz/2gAMAwEAAgADAAAAEGDv/8QAGxEBAAICAwAAAAAAAAAAAAAAAQARITFhkfD/2gAIAQMBAT8QAQmY0tZzR7UQrAp1P//EABkRAQACAwAAAAAAAAAAAAAAAAEAIRExgf/aAAgBAgEBPxAtbY5CA2n/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhQVExceH/2gAIAQEAAT8QXj3WCUUYazsTFBq1FVuaNTdaYkJLogIgEdBo6R5zQxhUSwBy985NIazoBASF+Mdkxb6yzahW5//Z',
                      },
                      images: {
                        fallback: {
                          src: '/static/5fceb15f6bcef53f146a99381fedb355/703b5/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.jpg',
                          srcSet:
                            '/static/5fceb15f6bcef53f146a99381fedb355/eb401/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.jpg 500w,\n/static/5fceb15f6bcef53f146a99381fedb355/b21dc/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.jpg 1000w,\n/static/5fceb15f6bcef53f146a99381fedb355/703b5/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5fceb15f6bcef53f146a99381fedb355/88b3a/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.webp 500w,\n/static/5fceb15f6bcef53f146a99381fedb355/94a39/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.webp 1000w,\n/static/5fceb15f6bcef53f146a99381fedb355/872a5/acb013a5-9072-4a91-8804-82293a51ca94_6a263ab6-c332-4d9e-9907-f7abc352ee1e_incidental-1.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1335,
                    },
                  },
                },
              },
            },
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.jpeg?auto=compress%2Cformat&rect=0%2C0%2C830%2C554&w=2000&h=1335',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAYHBf/EABYBAQEBAAAAAAAAAAAAAAAAAAUDBP/aAAwDAQACEAMQAAABxq3L6voZQB1Ii//EABoQAQACAwEAAAAAAAAAAAAAAAUDBAIGExT/2gAIAQEAAQUCq7qn6GOypRW5WB4CRu6gNzKe4+LEtd//xAAdEQAABgMBAAAAAAAAAAAAAAAAAQIDIYEEBREx/9oACAEDAQE/AdgyvHcSTqS7XlQIH//EABkRAAEFAAAAAAAAAAAAAAAAAAABAgMRQf/aAAgBAgEBPwGVMKcf/8QAIBAAAgEFAAIDAAAAAAAAAAAAAQIDAAQREiFBkQUx0f/aAAgBAQAGPwKOPKHbwUH5UMY2gdus1rw+6e0+VQPdRyMNpV6V8VFAZsIeZC991fJjSJJNERfpRSzSEqwQLyv/xAAgEAEAAQMDBQAAAAAAAAAAAAABIQARMUFRYXGRocHh/9oACAEBAAE/IW2ZWVI4M6ESvfCS8OzF/lav2QpeWps7VMBVjPNxRkJEMCO7q10+c0K+6//aAAwDAQACAAMAAAAQ89//xAAZEQEBAAMBAAAAAAAAAAAAAAABEQAhMWH/2gAIAQMBAT8QAEZYAFc0Tq+405//xAAZEQEAAgMAAAAAAAAAAAAAAAABABFBYcH/2gAIAQIBAT8QfVclclU//8QAHBABAQADAQEBAQAAAAAAAAAAAREAITFBgVFx/9oACAEBAAE/ECBvwui8i+PuD3YiwUQ5KHa80oxDAFlLHJT0BiMM9MiXSt/iTIOILO7ABYL6KuFhgiUQz3+fmf/Z',
                      },
                      images: {
                        fallback: {
                          src: '/static/a240d723275460ac80f3c33cd967ab00/703b5/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.jpg',
                          srcSet:
                            '/static/a240d723275460ac80f3c33cd967ab00/eb401/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.jpg 500w,\n/static/a240d723275460ac80f3c33cd967ab00/b21dc/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.jpg 1000w,\n/static/a240d723275460ac80f3c33cd967ab00/703b5/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/a240d723275460ac80f3c33cd967ab00/88b3a/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.webp 500w,\n/static/a240d723275460ac80f3c33cd967ab00/94a39/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.webp 1000w,\n/static/a240d723275460ac80f3c33cd967ab00/872a5/05194a90-c900-4a98-9456-70bd9f639e03_9b406771-9a77-45ec-ab30-66ec3b3ccb90_incidental-3.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1335,
                    },
                  },
                },
              },
            },
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.jpeg?auto=compress%2Cformat&rect=0%2C0%2C830%2C554&w=2000&h=1335',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUHA//EABYBAQEBAAAAAAAAAAAAAAAAAAMAAv/aAAwDAQACEAMQAAABrCqiqJMBMBr/xAAZEAEBAQEBAQAAAAAAAAAAAAAEAgUDART/2gAIAQEAAQUCtaovjrq1B4raUHuT6JXznMJOpRff/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAEREiH/2gAIAQMBAT8BUSMXp//EABkRAAIDAQAAAAAAAAAAAAAAAAABERMhQf/aAAgBAgEBPwHkFiWH/8QAIBAAAgICAgIDAAAAAAAAAAAAAQIDEQAEEyESURRBkf/aAAgBAQAGPwKQxaiiEV3J9+zknDrrruX40Z47/Ri/IVYdqM8cyemGIFk4wDZ6u8aZQC0YoUPEYyGNXN35Dq8//8QAHBABAAEFAQEAAAAAAAAAAAAAAREAITFRYUFx/9oACAEBAAE/IR/mSKT4bjTQ+5R7ku+Op1NKzIsQyo42T7T40nPxv2hZoALjGCxmgGGZMnK//9oADAMBAAIAAwAAABDYz//EABsRAAMAAgMAAAAAAAAAAAAAAAERIQAxQVGh/9oACAEDAQE/ECcKJ6svMXujh0Ann//EABgRAQEBAQEAAAAAAAAAAAAAABEBAEHw/9oACAECAQE/EFrq+daVU3//xAAaEAEBAQEBAQEAAAAAAAAAAAABESEAMUFR/9oACAEBAAE/EKEFkZsnuDA374cEixrJRLUAhSDvgKhMsUI3yYmQHNgACcDH4DC/oT7yaniBBIcKmBydgKLAa62IW7O//9k=',
                      },
                      images: {
                        fallback: {
                          src: '/static/897940a8b5d2b56a9545f1502f36bb86/703b5/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.jpg',
                          srcSet:
                            '/static/897940a8b5d2b56a9545f1502f36bb86/eb401/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.jpg 500w,\n/static/897940a8b5d2b56a9545f1502f36bb86/b21dc/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.jpg 1000w,\n/static/897940a8b5d2b56a9545f1502f36bb86/703b5/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/897940a8b5d2b56a9545f1502f36bb86/88b3a/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.webp 500w,\n/static/897940a8b5d2b56a9545f1502f36bb86/94a39/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.webp 1000w,\n/static/897940a8b5d2b56a9545f1502f36bb86/872a5/e2b51076-4675-40cc-8f33-d6b6ffb9ab6c_bc9b5508-2704-45d7-95db-345e962b301c_incidental-4.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1335,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
