const data = {
  name: 'Products Grid',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            tagline: {
              html: '<p>RASTRUM™ Consumables</p>',
              text: 'RASTRUM™ Consumables',
            },
            title: {
              html: '<h4>Off-the-shelf consumables to accelerate your experimentation</h4>',
              text: 'Off-the-shelf consumables to accelerate your experimentation',
            },
          },
          items: [
            {
              feature_1: {
                html: '<p>Single-use medical grade plastic</p>',
                text: 'Single-use medical grade plastic',
              },
              feature_2: {
                html: '<p>Sterile and supplied with cleaning and sterilisation fluids</p>',
                text: 'Sterile and supplied with cleaning and sterilisation fluids',
              },
              feature_3: {
                html: '<p>Eight user-fillable reservoirs able to load very low volumes of cell suspensions</p>',
                text: 'Eight user-fillable reservoirs able to load very low volumes of cell suspensions',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2ElEQVQ4y+3UTU8TQRgHcD6WXjQe5EKUpCktW7RbthYqcjBevftpNAHFyra73ZZSaynVVkUuCCSlaHd3XnZm37ovHdNGVMLCxSv//C+TzC9zeJ7MFPmPTF1jQk1KLTO6Jr0SmxTrEPYG8ESF/bOequhUQ30V64Cc92fYmMgB+LkqatyGli5ofAEKRfyogperRr5OVpv4eQv3NWLR8eXz2KCuBfZPaFwMMlseX/GF2khostwnlu+yx3vs2UGQ/Yr3+sQ1o7Bjgf2eGd8MEmWPk72Fsp/e8rPbfq4R5j+yla6/2MLfLsOupX05hrF1NyU58ZKdKDlJ2Xmg2Bkl4BvsScfP7KLPPeJaxDAuYlvtHmmzr2xORLdewxtr6Oa6cfstuvPGmZZH+bb3cAe0j+kwEg9ttfNdnXlppURrbpMmCnTunZ0UzQXRy9TYcttLfdB3j67Ah3BmzeMUNym785KTKLqc5PJKsPieLbWHqabeOhxjfAk27m6w2HZ4rxrer4Sxahivhcn6aL7B+M5wtq41D6hnX8STOf/QBk9lmC4BQdLTos4XoSDBbBnlFJytwhc7GEBCafSGUZNigpEOEABI1/92fASGNX7gz5yidpvQ3zH/6STXn8EY/wIPFpdKAuHIDAAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/46a88de354e18bb335cab84c0aa717bc/8f43c/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.png',
                          srcSet:
                            '/static/46a88de354e18bb335cab84c0aa717bc/2cbef/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.png 115w,\n/static/46a88de354e18bb335cab84c0aa717bc/803da/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.png 230w,\n/static/46a88de354e18bb335cab84c0aa717bc/8f43c/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.png 460w,\n/static/46a88de354e18bb335cab84c0aa717bc/c73ea/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.png 920w',
                          sizes: '(min-width: 460px) 460px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/46a88de354e18bb335cab84c0aa717bc/30df9/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.webp 115w,\n/static/46a88de354e18bb335cab84c0aa717bc/ee174/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.webp 230w,\n/static/46a88de354e18bb335cab84c0aa717bc/99252/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.webp 460w,\n/static/46a88de354e18bb335cab84c0aa717bc/114bd/fc83ac1e-7fdf-4c0a-ba9d-a793644203f5_c1e233bf-4690-4d9d-a07d-23ab9793216e_box.webp 920w',
                            type: 'image/webp',
                            sizes: '(min-width: 460px) 460px, 100vw',
                          },
                        ],
                      },
                      width: 460,
                      height: 500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>RASTRUM™ Cartridge</h4>',
                text: 'RASTRUM™ Cartridge',
              },
            },
            {
              feature_1: {
                html: '<p>Validated printable matrix components</p>',
                text: 'Validated printable matrix components',
              },
              feature_2: {
                html: '<p>Tuned stiffness and biofunctionality for each cell type</p>',
                text: 'Tuned stiffness and biofunctionality for each cell type',
              },
              feature_3: {
                html: '<p>RASTRUM™ Cell Recovery Solution for cell extraction</p>',
                text: 'RASTRUM™ Cell Recovery Solution for cell extraction',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAvklEQVQ4y+3UTQuCQBAG4P7/fzKL0NSDuSqkURCCmPs57kz30Et08DB17b0/DO+wOxv/QzZ//A6if84hRD4GgK7ru64HAB4mwmGQ+20WBun4UETIwEgoR53E4hgVRltEFkbvHFTiUuQNOFirvYbRWne79U1zN9ZxJ8+4rq67MFVKMzsjGmNL0WZpKaUmIu5kW1fXPD/LUXHxvLDi1Cax0NrwOnvvp8lHhzwM0mXbyH7bWhkp9ZcfA5f8j8EHfgH1Otc+Fcq8egAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/023a00cf096645e3b8316de87461d2e5/8f43c/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.png',
                          srcSet:
                            '/static/023a00cf096645e3b8316de87461d2e5/2cbef/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.png 115w,\n/static/023a00cf096645e3b8316de87461d2e5/803da/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.png 230w,\n/static/023a00cf096645e3b8316de87461d2e5/8f43c/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.png 460w,\n/static/023a00cf096645e3b8316de87461d2e5/c73ea/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.png 920w',
                          sizes: '(min-width: 460px) 460px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/023a00cf096645e3b8316de87461d2e5/30df9/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.webp 115w,\n/static/023a00cf096645e3b8316de87461d2e5/ee174/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.webp 230w,\n/static/023a00cf096645e3b8316de87461d2e5/99252/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.webp 460w,\n/static/023a00cf096645e3b8316de87461d2e5/114bd/eeb2dfc5-c7fb-4c96-aaf2-8b1a50952ef1_4888bc3a-3977-4c19-80eb-818d96aeb74c_vial.webp 920w',
                            type: 'image/webp',
                            sizes: '(min-width: 460px) 460px, 100vw',
                          },
                        ],
                      },
                      width: 460,
                      height: 500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>RASTRUM™ Matrices</h4>',
                text: 'RASTRUM™ Matrices',
              },
            },
            {
              feature_1: {
                html: '<p>A range of standard SBS well plate formats (6, 24, 48, 96 and 384-well plates)</p>',
                text: 'A range of standard SBS well plate formats (6, 24, 48, 96 and 384-well plates)',
              },
              feature_2: {
                html: '<p>User-specific well plate formats supported on request</p>',
                text: 'User-specific well plate formats supported on request',
              },
              feature_3: {
                html: '',
                text: '',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate+%281%29.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAAsTAAALEwEAmpwYAAABjUlEQVQ4y92UW2+jMBBG+///VIHY+BaThSi0dEu4JwQIxLf3lYna3VYNUXff1jpC38vRaDweHuQ/nIf/Q1a3j1RqSVZKjtN06oeuP3fDH/TnUz+M0+WT/lsWUmqty/oQJ/uXn1nykThJy8NRay2+loU0WudVw9mW44iT8J0Ah5xty/pgtBbia1kYrbOyonjD/A1DGwIDAgM6Z4o3WVXPsrgp7/MS+wEBHK0Y8mZWjACO4XpfVMYsyEanWeEDhjzquwQ6xHeJ71ILYPuiNMYsyOY1y6FHfYf4DoEOho94DgR4JM2Lu3IB57IWh8BHbOs7FHg0vVdZp3mJwBp5DNuGKXKpbdtlPrjb89ttU8CJZW2/qzmjYPm25znX1zmHDP14h1/n3Nyes5RSK1Uf2234HAVxFMQh34V8Z0MQR+Fz0560UkLelg9d/7RL4zCJoxdLeCV52r0eu15/fNyfFkO13VDUbd2cKkt7pWzaom7b/rwkSymni+jH6TxOwzQN48xbmC7i7/dZLe/zd/8kvwD6Sq8MKEEAOgAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/f27327e7b69fe36faa9e3c9c62a9ed4f/8f43c/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.png',
                          srcSet:
                            '/static/f27327e7b69fe36faa9e3c9c62a9ed4f/2cbef/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.png 115w,\n/static/f27327e7b69fe36faa9e3c9c62a9ed4f/803da/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.png 230w,\n/static/f27327e7b69fe36faa9e3c9c62a9ed4f/8f43c/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.png 460w,\n/static/f27327e7b69fe36faa9e3c9c62a9ed4f/c73ea/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.png 920w',
                          sizes: '(min-width: 460px) 460px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/f27327e7b69fe36faa9e3c9c62a9ed4f/30df9/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.webp 115w,\n/static/f27327e7b69fe36faa9e3c9c62a9ed4f/ee174/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.webp 230w,\n/static/f27327e7b69fe36faa9e3c9c62a9ed4f/99252/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.webp 460w,\n/static/f27327e7b69fe36faa9e3c9c62a9ed4f/114bd/165de2c0-51f9-478b-818b-ee172fb140a2_e40a9f9e-1972-41c8-9d79-1ba8d1580494_plate%2B%25281%2529.webp 920w',
                            type: 'image/webp',
                            sizes: '(min-width: 460px) 460px, 100vw',
                          },
                        ],
                      },
                      width: 460,
                      height: 500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>Standard well plates</h4>',
                text: 'Standard well plates',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
