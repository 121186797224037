const data = {
  name: 'Two Col Title Text',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>A simple platform to create 3D cell models in standard well plates that are compatible with your existing experimental workflow.</p>',
              text: 'A simple platform to create 3D cell models in standard well plates that are compatible with your existing experimental workflow.',
            },
            title: {
              html: '<h2>RASTRUM™ Experience</h2>',
              text: 'RASTRUM™ Experience',
            },
          },
        },
      },
    },
    {
      title: 'No left title',
      props: {
        data: {
          primary: {
            content: {
              html: '<h2>We’re Inventia Life Science<br /></h2><p>Inventia Life Science Pty Ltd (ILS) is an Australian privately-owned company established in Sydney in 2013. ILS has a core focus on the development, manufacture and sale of equipment and reagents for advanced medical research assays by means of 3D bioprinting.</p><p>ILS’ approach to low cost and standardised bio-based additive fabrication of 3D human tissue and organ models will transform the medical research sector by providing the hardware, software, consumables and reagents, as well as customised protocols, to enable the flexible production of 3D cell based assays.</p>',
              text: 'We’re Inventia Life Science\n Inventia Life Science Pty Ltd (ILS) is an Australian privately-owned company established in Sydney in 2013. ILS has a core focus on the development, manufacture and sale of equipment and reagents for advanced medical research assays by means of 3D bioprinting. ILS’ approach to low cost and standardised bio-based additive fabrication of 3D human tissue and organ models will transform the medical research sector by providing the hardware, software, consumables and reagents, as well as customised protocols, to enable the flexible production of 3D cell based assays.',
            },
            title: {
              html: '<h2></h2>',
              text: '',
            },
          },
        },
      },
    },
  ],
};

export default data;
