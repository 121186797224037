const data = {
  name: 'Header',
  variations: [
    {
      title: 'Video',
      props: {
        data: {
          primary: {
            background_colour: null,
            background_image: {
              url: null,
              localFile: null,
            },
            background_video: {
              url: 'https://player.vimeo.com/video/402815398?h=dbe4137e2c&title=0&byline=0&portrait=0&muted=1&autoplay=1&autopause=0&controls=0&loop=1&app_id=122963',
            },
            content: {
              html: '',
              text: '',
            },
            cta_link: {
              url: '',
            },
            cta_text: {
              html: '<p>See how it works</p>',
              text: 'See how it works',
            },
            modal_video: {
              url: 'https://player.vimeo.com/video/390273856?h=6d98fe0ea1&title=0&byline=0&portrait=0&playsinline=0&autoplay=1&autopause=0&app_id=122963',
            },
            title: {
              html: '<h1>Revolutionising 3D cell biology</h1>',
              text: 'Revolutionising 3D cell biology',
            },
          },
        },
      },
    },
    {
      title: 'Image',
      props: {
        data: {
          primary: {
            background_colour: null,
            background_image: {
              url: 'https://images.prismic.io/inventia-life/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2880%2C1920&w=2000&h=1333',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAYHBAj/xAAXAQADAQAAAAAAAAAAAAAAAAAAAgQF/9oADAMBAAIQAxAAAAHW1K15a7lstgZ//8QAGxAAAgMAAwAAAAAAAAAAAAAAAgQDBQYBBxP/2gAIAQEAAQUCyWntXGdMY3R1O6tM/B1+IvNcR+cl+qBOf//EABsRAAIBBQAAAAAAAAAAAAAAAAABEQIDBBJR/9oACAEDAQE/AciKbmzRK4f/xAAaEQACAgMAAAAAAAAAAAAAAAAAAQMRISJR/9oACAECAQE/AZNsIp9P/8QAIxAAAgEDAwQDAAAAAAAAAAAAAQIDAAQSERNhISIjQjEzcf/aAAgBAQAGPwIR/dFlh5DrUFixlt3Q7h2+mX7UlnJCl06SHV7gksOKSfERhAWwUfJ5pl9RSlVCdnqOTX//xAAdEAEAAgICAwAAAAAAAAAAAAABESEAQTFRcaGx/9oACAEBAAE/IXfBVc/dxOnH4Qmp0PD5kk5sY4t1XvFAJdCRBW4nBFVAQenWUeNJBLyVn//aAAwDAQACAAMAAAAQa9//xAAaEQEBAAMBAQAAAAAAAAAAAAABEQAhQTFR/9oACAEDAQE/EII6lSANvCBfSGvnMaax/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQARMdH/2gAIAQIBAT8QLZK7Atj/xAAbEAEBAQEBAQEBAAAAAAAAAAABESEAMUFhcf/aAAgBAQABPxBCyqxdKckECtYZwKNEzYMbNWtEZzh87GBCnOj+uMqio8EB2pfObGgtRFp+z5+cISco+woFkLLh3//Z',
                    },
                    images: {
                      fallback: {
                        src: '/static/5b7a61550a755e4e4dddb4c1835d72a6/fd8c5/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.jpg',
                        srcSet:
                          '/static/5b7a61550a755e4e4dddb4c1835d72a6/8cc9b/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.jpg 500w,\n/static/5b7a61550a755e4e4dddb4c1835d72a6/ee12c/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.jpg 1000w,\n/static/5b7a61550a755e4e4dddb4c1835d72a6/fd8c5/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.jpg 1999w',
                        sizes: '(min-width: 1999px) 1999px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/5b7a61550a755e4e4dddb4c1835d72a6/759ea/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.webp 500w,\n/static/5b7a61550a755e4e4dddb4c1835d72a6/38534/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.webp 1000w,\n/static/5b7a61550a755e4e4dddb4c1835d72a6/89b74/f0254cc9-2f31-4158-9976-ff50a56e1abe_de4978ee-6233-4bdc-9750-6b4cf7db2b97_platform.webp 1999w',
                          type: 'image/webp',
                          sizes: '(min-width: 1999px) 1999px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 1333.6668334167084,
                  },
                },
              },
            },
            background_video: {
              url: '',
            },
            content: {
              html: '',
              text: '',
            },
            cta_link: {
              url: '',
            },
            cta_text: {
              html: '',
              text: '',
            },
            modal_video: {
              url: '',
            },
            title: {
              html: '<h1>RASTRUM™ is built for 3D cell biology, accelerating drug discovery and biomedical research with the power of digital bioprinting.</h1>',
              text: 'RASTRUM™ is built for 3D cell biology, accelerating drug discovery and biomedical research with the power of digital bioprinting.',
            },
          },
        },
      },
    },
    {
      title: 'No Media',
      props: {
        data: {
          primary: {
            background_colour: null,
            background_image: {
              url: null,
              localFile: null,
            },
            background_video: {
              url: '',
            },
            content: {
              html: '',
              text: '',
            },
            cta_link: {
              url: '',
            },
            cta_text: {
              html: '',
              text: '',
            },
            modal_video: {
              url: '',
            },
            title: {
              html: '<h1>Resources</h1>',
              text: 'Resources',
            },
          },
        },
      },
    },
  ],
};

export default data;
