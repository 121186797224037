const data = {
  name: 'Hover Grid',
  variations: [
    {
      title: '',
      props: {
        data: {
          items: [
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.png?auto=compress%2Cformat&rect=0%2C0%2C565%2C565&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAIAAAAC64paAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC40lEQVQ4y2VUS28cRRCu6np1987s7MPreLze2bUhMbbycBwkQEsShUQJD8kOygmZQyTkCAWBkCESCJFIFgTCgQNC4oY4IE4c+QOc+VPQM2uvY0bfoaq6vurq+koDiIAIs6+xj1wkTKiDCQ4aF44Brs7rEm7EZNeuu9OjO31oThGO480FJ8hcV/ig1D8vJotSAr1/hu8N+PUO325KpBMcG727CFTbM3LdJBaEfQaY95zKfrmsB8sOgC4EzMldK/Sfy65UaO7A4zaajhgxc26Yjvms93c7cSvSqtpPa3qzQEW+ldOlFuqpNwBg5uSjodv0+nkpG4FXVBDk1ahPyvBsTbZaejiRBdEHK7Qa6YsRfzo6QfZoX625nNy9buu9QauU8Nko7vTCk5GtBwSQRyv+t/NSp9PjMe8P09Odq5UAoGsdmhg70EgZO31cxb2+7PTcNMMFEkbq8LGW2IjhEDAjKjjNRmdaGoEZkE85YdPzNKNVj4KN4ImVJKhHL3vD1sNKJ0F2B/iS6dtFvt3KN31Ik7O4bvr9xA7HziMdVrQRZyvAjPbmQIfGHmkceCvnl6PtdcNukT0q8/0lGqvvcL4gOm3zmnev5W5BZuSo2PlkEqsgAIrQAtSnlXwz5P1edn/QvhD1YRkPRi0AvduTK/l87bDeFaldysjvr/icbJrpNFcGvZr5G20ZMPeIf6j8hyU9x2ymRimUhvTsrP6x7rfbCqBfV/xjJW91BMBfCva08gcjuhzpajG/fFaDkABku21/X7H7S3qjLQfL8uCMTXNbD/58iMuWvdHj7yr7+RyeIjsEIRBGbbGUYjcLe6ev347k1xf87a5EZEXpOL3edqXOyYhALjG1gQMD0EWyj0v5/UW/2/cC9tfFsLMoHuyXcxQJTpLTStFzMI8a0L/SDoVEQ7vetaGpA1kSZpj/D8gB02myuiSbIXit7VpFpaQLpd06Iqen/p/cQDAhGZCUO4qzS/ivxL9BDkROk84x8gAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/f2eaf20a650222b14691d9bc5741d241/6d9ea/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.png',
                          srcSet:
                            '/static/f2eaf20a650222b14691d9bc5741d241/e08cd/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.png 500w,\n/static/f2eaf20a650222b14691d9bc5741d241/95fae/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.png 1000w,\n/static/f2eaf20a650222b14691d9bc5741d241/6d9ea/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/f2eaf20a650222b14691d9bc5741d241/7bf44/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.webp 500w,\n/static/f2eaf20a650222b14691d9bc5741d241/61fb4/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.webp 1000w,\n/static/f2eaf20a650222b14691d9bc5741d241/836c3/3e4c73c6-29a0-419c-8af6-048263da2bbc_f7a23cc7-f4c2-4cfd-8756-ee1987b0f2b0_tumourspheroids.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 2000,
                    },
                  },
                },
              },
              modal_image: {
                url: 'https://images.prismic.io/inventia-life/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.png?auto=compress%2Cformat&rect=0%2C0%2C790%2C788&w=2000&h=1995',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEdUlEQVQ4y2WSXW8TVxCGZ8+e3bV91sGmSZ2QBEhToAVKm0pVJXpXqVftL+hPKCo0QLwhibHj9UcSYidxVonjEDd82KgtIEFAlEsUVCQuEJfhqvyKEulcvNWsEyG1F6Oj3Zl5533mHGo0Grhx4wYWFxeRz+dx7do1bGxsoF6vo1ar6bm5OWSz2czq6mpw69YtLNVqulwug6NSqYD7W60W7t69i62tLVChUEC1Wg0LJicnUa1WcPPmTSwv17C6uqrn5+dRKpUyhUIhmJ2dZXGdTqfDwcvLywiCADzo4cOHePbsGWh6ejpMsujExAQymSmwK27O5/Oaz5mZmYzv+8HVq1dx+fJlPTY2FuY5FhYWQoePHz/Gy5cvQVNTU4wGdup5HjeH6KVSiUPzsGKxmJmeng6y2SwmJq7o0dHRcOi+w9u3b+PBgwd49eoViFEZi/fBovtuc7kcr0FXK1VUqtVMNpsNrlwZx8WLo3qfisXW1tZw7949PHnypOOQEyy2vr4e2meMlZWV8JLq9br2fR/pdDpTLpcDHsI7ZAJ2yz3tdhubm5uh4OvXr1lwLhS68MsFeF46xOdCv+DzTkM3+Xw+FCwWi/B9P7xl/s/Yd+7cCW94e3sbb968Ac3Pz+tKpcKNenJyUp8//7PuYOX0uXM/7fIaCoXCVLlcru2J73It53O5nG42m/r+/ft6e3tb7+zsaGJERl5aWgpRL126iPHx8XDh7Jxzvu+XisVikx3yZTEyD+L6/Sfz/Plz7OzshDv0ZmdnvZWVFe9647q3tlb3th498q5vbHjNzV/H2u12ttlsnl1YXPxuZmYmW11YGGusr3utVtv7/bc/vD+fPvX+evHC+/vtW++fd+88Gho62jh56tNGJOI0iOi/USeiFhH9MNiT/PHrkTOtw6kP6g7nJDVI0P97hk8M48vvv0I86kJ0S8ijDoQUMCMCsteGSQaIqPRR6sPm2ZERDPf3IiEl3KSJeJ+N+EGJuDLRJQx0WYJrSYeRMLRQQss+qa0Tjpad2BUHTYiDcioapZqMECIG7cYOSa0GLK16pI7x2W3q2ElHK0maDCKIqIAcdiCTJqxhB/ZnEZi9EuaApcUhC+J0JOPGReAecaD6pI4dtaEOCMR6JWKfOFAJAeUaUCaBSFCIKroETNuATJgwlYDstyASpjYkF1EmThSoKMEdtLU6FUUsJaG6TcQ+thEbskMxFTVAMiW5EeKAgHMmisiQDWvQgjVgQaSkNmyjI2hSwAJur6XVN3HETkegekyo4w5U0oRKCihlgIRJMKIG5BfR0JV1zIGZNGF/Hu0IEoEMyriCAhUxoOJCu10mo4fYqktADVhwu2VHkIjAWOapCOSQDedbF7JPwuyRkCNRTRa9d2gQePGhsEVQvMt+C4qReZ8GDzfChjCEK2AddyAHLJh9EsI1tGVJmJbsODQJriCt3I4zVwm4KQuxpEBXzETClnsODYIh3gsbe8HPaXBwAKneVCZCFLh7gq4gKJvgGuE3ogbhUKILxw7341/pFtbbil+7lAAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/55f34cfbf86b6485759808143ef2e9da/0f31b/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.png',
                          srcSet:
                            '/static/55f34cfbf86b6485759808143ef2e9da/62831/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.png 500w,\n/static/55f34cfbf86b6485759808143ef2e9da/9e817/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.png 1000w,\n/static/55f34cfbf86b6485759808143ef2e9da/0f31b/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/55f34cfbf86b6485759808143ef2e9da/e6825/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.webp 500w,\n/static/55f34cfbf86b6485759808143ef2e9da/65a0f/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.webp 1000w,\n/static/55f34cfbf86b6485759808143ef2e9da/84cca/10be902f-f03a-4a3a-9d62-56cc75db080f_37f6723d-bcd1-42f9-a4a2-3e534d6fcf78_tumor-spheroids-detail.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1995,
                    },
                  },
                },
              },
              post_hover_text: {
                html: '<p>3D multicellular tumour spheroid models that resemble small tumours and micrometastases</p>',
                text: '3D multicellular tumour spheroid models that resemble small tumours and micrometastases',
              },
              pre_hover_text: {
                html: '<p>Tumour spheroids</p>',
                text: 'Tumour spheroids',
              },
              read_more_text: {
                html: '<p>3D multicellular tumour spheroid models that resemble small tumours and micrometastases are routinely used by researchers to study the biology of solid tumours <em>in vitro</em>. Matrix-embedded tumour spheroids enable the inclusion of key elements of the extracellular matrix within the tumour model, which better recapitulates the <em>in vivo</em> tumour context. Learn more <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/0d408fc8-a028-48ac-b87e-2be57e09fe96_20200309+-+Tumour+Spheroids+App+Note+20200724.pdf">here</a>.</p><p></p>',
                text: '3D multicellular tumour spheroid models that resemble small tumours and micrometastases are routinely used by researchers to study the biology of solid tumours in vitro. Matrix-embedded tumour spheroids enable the inclusion of key elements of the extracellular matrix within the tumour model, which better recapitulates the in vivo tumour context. Learn more here. ',
              },
            },
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%2BMigration.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1920%2C1280&w=2000&h=1333',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAYHBf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABZotfF4UU3TeP/8QAGRAAAwEBAQAAAAAAAAAAAAAAAQIEAwUU/9oACAEBAAEFAuolDQPnpG2TehVcgdOLG1Aqzj//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQACEf/aAAgBAgEBPwFz2C//xAAgEAABBAEEAwAAAAAAAAAAAAARAAECAxIQEzFhFCEj/9oACAEBAAY/ArfFjlcB2Ol96ngA7tP0VlVLbr4ZhozXRyDnkLGERFf/xAAaEAADAAMBAAAAAAAAAAAAAAAAAREhMUFR/9oACAEBAAE/IZgOgk5TfiiKn0BquCY+1baR7rHR7RIY2WicyuH/2gAMAwEAAgADAAAAEMPP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxCGDZ//xAAWEQEBAQAAAAAAAAAAAAAAAAABEQD/2gAIAQIBAT8QZ3LC7//EABoQAQEBAQEBAQAAAAAAAAAAAAEhEQAxUXH/2gAIAQEAAT8QEM1YTQ2fEoMaRR4fNYLViAaALNpeH76S8gO3f3nB70nrOJbZXDLqPxS8IsQFvf/Z',
                      },
                      images: {
                        fallback: {
                          src: '/static/5b3301b26c92c7f8db3f3d70a54eeb73/32e97/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.jpg',
                          srcSet:
                            '/static/5b3301b26c92c7f8db3f3d70a54eeb73/8cc9b/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.jpg 500w,\n/static/5b3301b26c92c7f8db3f3d70a54eeb73/ee12c/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.jpg 1000w,\n/static/5b3301b26c92c7f8db3f3d70a54eeb73/32e97/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5b3301b26c92c7f8db3f3d70a54eeb73/759ea/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.webp 500w,\n/static/5b3301b26c92c7f8db3f3d70a54eeb73/38534/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.webp 1000w,\n/static/5b3301b26c92c7f8db3f3d70a54eeb73/6a81a/aae0b2f3-e960-4814-8c20-f61539db2b9a_4da2ca4d-a358-42af-a0fc-d31cc540a3d5_3D%252BMigration.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1333,
                    },
                  },
                },
              },
              modal_image: {
                url: null,
                localFile: null,
              },
              post_hover_text: {
                html: '<p>Cell migration models for studying processes such as metastasis and inflammation</p>',
                text: 'Cell migration models for studying processes such as metastasis and inflammation',
              },
              pre_hover_text: {
                html: '<p>3D migration / invasion assays</p>',
                text: '3D migration / invasion assays',
              },
              read_more_text: {
                html: '<p>Cell migration is critical for normal development, immune response, and disease processes such as cancer metastasis and inflammation. While cell migration has been traditionally assayed in a 2D context, we are currently developing 3D cell migration and invasion models to mimic the native, <em>in vivo </em>environment and support realistic migration behaviour for research applications.</p>',
                text: 'Cell migration is critical for normal development, immune response, and disease processes such as cancer metastasis and inflammation. While cell migration has been traditionally assayed in a 2D context, we are currently developing 3D cell migration and invasion models to mimic the native, in vivo environment and support realistic migration behaviour for research applications.',
              },
            },
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%2Bco-culture.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1920%2C1602&w=2000&h=1669',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAARABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAQDBQf/xAAXAQEBAQEAAAAAAAAAAAAAAAAEAwIG/9oADAMBAAIQAxAAAAHyLLSIfHyirupARLONL//EAB0QAAIBBAMAAAAAAAAAAAAAAAECAwAREjMTITH/2gAIAQEAAQUCFNxpUjgsX6c2klzzOkeybf/EABsRAAICAwEAAAAAAAAAAAAAAAABAhEDMUHh/9oACAEDAQE/AYq2RSr0x9Fo/8QAFxEBAQEBAAAAAAAAAAAAAAAAARARMf/aAAgBAgEBPwEcYdn/xAAfEAACAQQCAwAAAAAAAAAAAAAAARECITFxMlFBYfD/2gAIAQEABj8CbrxETHkvVi+ZOM6Y/fRF4IpSaQ9j+6K9n//EAB4QAAICAgIDAAAAAAAAAAAAAAERACExUaHwYbHR/9oACAEBAAE/IRFMEdwOhBoIBkCK1G44gnQil6HBLYUlsP5BoEJi53vM5kcnP//aAAwDAQACAAMAAAAQE9d8/8QAGxEAAQUBAQAAAAAAAAAAAAAAAAERITFxsdH/2gAIAQMBAT8QXkgY5YU14UYnD//EABkRAAMBAQEAAAAAAAAAAAAAAAABMSFR8P/aAAgBAgEBPxBmBdLe4KI//8QAHhABAQEAAgMAAwAAAAAAAAAAAREhADEQQXFh4fD/2gAIAQEAAT8QQ3G8bKAL0U/erxBPcmVwC9kkVmcfrgIgEMYJf1wgwVkUIRHNvVNm/UMZopnqWpolepM5WHqzt21hu74N/T+fAX//2Q==',
                      },
                      images: {
                        fallback: {
                          src: '/static/6ea487f4f836967c18941da56567d786/5adff/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.jpg',
                          srcSet:
                            '/static/6ea487f4f836967c18941da56567d786/eb17c/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.jpg 500w,\n/static/6ea487f4f836967c18941da56567d786/d28c3/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.jpg 1000w,\n/static/6ea487f4f836967c18941da56567d786/5adff/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/6ea487f4f836967c18941da56567d786/4daf0/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.webp 500w,\n/static/6ea487f4f836967c18941da56567d786/7bbd9/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.webp 1000w,\n/static/6ea487f4f836967c18941da56567d786/7695e/babe8b58-83c2-4d30-b315-f92d0134c50c_7140e676-85df-4bdd-a395-89478ffa65dd_Cancer%252Bco-culture.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1669,
                    },
                  },
                },
              },
              modal_image: {
                url: null,
                localFile: null,
              },
              post_hover_text: {
                html: '<p>Inclusion of stromal cell types to study tumour initiation, proliferation and metastasis</p>',
                text: 'Inclusion of stromal cell types to study tumour initiation, proliferation and metastasis',
              },
              pre_hover_text: {
                html: '<p>Cancer co-culture</p>',
                text: 'Cancer co-culture',
              },
              read_more_text: {
                html: '<p>Stromal cell types, including endothelial cells, fibroblasts and immune cells, have been studied for their contributing roles in tumour initiation, proliferation and metastasis. We are currently optimising models enabling the co-culture of cancer cells and other cell types, which better recapitulates the dynamic cell-cell interactions that influence tumour behaviour <em>in vivo</em>.</p>',
                text: 'Stromal cell types, including endothelial cells, fibroblasts and immune cells, have been studied for their contributing roles in tumour initiation, proliferation and metastasis. We are currently optimising models enabling the co-culture of cancer cells and other cell types, which better recapitulates the dynamic cell-cell interactions that influence tumour behaviour in vivo.',
              },
            },
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1440%2C1008&w=2000&h=1400',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAOABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAcEBv/EABYBAQEBAAAAAAAAAAAAAAAAAAUCA//aAAwDAQACEAMQAAABgNR4Tfk2U0Xf/8QAGhABAQEBAAMAAAAAAAAAAAAAAwUEAgARFP/aAAgBAQABBQISHvyWePhLUAM9EanzzYdIyXLt9l//xAAXEQEBAQEAAAAAAAAAAAAAAAABAgAT/9oACAEDAQE/AWznQmZkd//EABoRAAICAwAAAAAAAAAAAAAAAAABAxESIVH/2gAIAQIBAT8Bx4KaRao//8QAIhAAAQMCBgMAAAAAAAAAAAAAAgABAxESBBMiUWGRFCEx/9oACAEBAAY/Arfl2/vpWPABRbONe1I2HvyXoQizVt4UmFyIyKR65ik8oCla3TRagY+XX//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQXGRofH/2gAIAQEAAT8hdaroNiueW5iTNBT5bjhg1DMXZ79VM5wBlJ84goC6O5ml14TFdM//2gAMAwEAAgADAAAAEHc//8QAGhEBAQACAwAAAAAAAAAAAAAAAREAIVFhgf/aAAgBAwEBPxCk43SJU56xwFnmf//EABgRAQEBAQEAAAAAAAAAAAAAAAERAGEx/9oACAECAQE/EAMqvfMDQZ3f/8QAGhABAQEBAQEBAAAAAAAAAAAAAREhMQBBcf/aAAgBAQABPxC0PSVwpSkXE+d54myNnHB+lFO2v56YNc7NNTlTrVNvo4DgVpqBrHCT1yFiws1SlXN2TylawJMIRjmwZVnv/9k=',
                      },
                      images: {
                        fallback: {
                          src: '/static/64e44eeb92e54ffa5b9f1eea46612f84/cb1ff/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.jpg',
                          srcSet:
                            '/static/64e44eeb92e54ffa5b9f1eea46612f84/24183/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.jpg 500w,\n/static/64e44eeb92e54ffa5b9f1eea46612f84/00283/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.jpg 1000w,\n/static/64e44eeb92e54ffa5b9f1eea46612f84/cb1ff/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/64e44eeb92e54ffa5b9f1eea46612f84/1a520/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.webp 500w,\n/static/64e44eeb92e54ffa5b9f1eea46612f84/edcf3/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.webp 1000w,\n/static/64e44eeb92e54ffa5b9f1eea46612f84/4b83f/3f55e6b9-e2cb-4d67-a95c-4c05e97ef65e_54c227ab-7ff3-4326-ae67-eebdbade6eba_Immunooncology.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1400,
                    },
                  },
                },
              },
              modal_image: {
                url: null,
                localFile: null,
              },
              post_hover_text: {
                html: '<p>Combination of immune cells and cancer cells to better mimic patient tumour responses</p>',
                text: 'Combination of immune cells and cancer cells to better mimic patient tumour responses',
              },
              pre_hover_text: {
                html: '<p>Immuno-oncology models</p>',
                text: 'Immuno-oncology models',
              },
              read_more_text: {
                html: '<p>Immuno-oncology <em>in vitro </em>models aim to decipher the relationship between immune cells and cancer cells, for prediction of drug efficacy prior to pre-clinical models. Inventia Life Science is undertaking research to incorporate major immune cell types and cancer cells in 3D models, to better mimic the patient tumour response to anti-cancer drugs compared to cell lines. </p>',
                text: 'Immuno-oncology in vitro models aim to decipher the relationship between immune cells and cancer cells, for prediction of drug efficacy prior to pre-clinical models. Inventia Life Science is undertaking research to incorporate major immune cell types and cancer cells in 3D models, to better mimic the patient tumour response to anti-cancer drugs compared to cell lines. ',
              },
            },
            {
              image: {
                url: 'https://images.prismic.io/inventia-life/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%2BCoCulture.jpeg?auto=compress%2Cformat&rect=0%2C0%2C1920%2C1920&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAMEBQf/xAAZAQACAwEAAAAAAAAAAAAAAAACBAEDBQb/2gAMAwEAAhADEAAAAeZ2aZXd0mYG2OMc53MEF//EABwQAAIDAAMBAAAAAAAAAAAAAAECAAMEERMUIf/aAAgBAQABBQLPkPZ4gsfKryvWYNX0WmwIBa11ajGijj//xAAbEQACAgMBAAAAAAAAAAAAAAABAgQRAAMQIf/aAAgBAwEBPwFWhLRAwztF+L3/xAAaEQAABwAAAAAAAAAAAAAAAAAAAQIQEyNR/9oACAECAQE/AbDEStf/xAAmEAABAwEFCQAAAAAAAAAAAAABAAIREgMhIkFREBMxUmFxcoGh/9oACAEBAAY/ArR9sGvnTIdFIg+roQduy+c0011alONdBAgDmQOIePBXtF+L4jaU49e4Oz//xAAeEAEBAAICAgMAAAAAAAAAAAABEQAhMWFxwUFRsf/aAAgBAQABPyHb6wgnZ2wmVK+AW7+zH1CaH3hrEaJMKbvjecV8s9N+sLtvkNrHmolATbWD4QW+y/hi+E44es//2gAMAwEAAgADAAAAECfgff/EABwRAQACAQUAAAAAAAAAAAAAAAEAIXEQETFB4f/aAAgBAwEBPxCzWu15xDopx7oBtP/EABgRAQADAQAAAAAAAAAAAAAAAAEAEBEh/9oACAECAQE/EE2Fhj23/8QAHRABAQACAwEBAQAAAAAAAAAAAREAITFBUXGBof/aAAgBAQABPxABKnx6l4Q057F/EAelsSNFapR8e8HhkWKbINxEf5gpdnyykJ6GrZTOAHRUvR2DZRjFnGa2dJ1Tw/JrBnRdRrHy8eYniGSq0A65h8yh/YHTxz//2Q==',
                      },
                      images: {
                        fallback: {
                          src: '/static/d15a833de76f0b2d914c1b0d1f9d4836/99fd6/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.jpg',
                          srcSet:
                            '/static/d15a833de76f0b2d914c1b0d1f9d4836/330de/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.jpg 500w,\n/static/d15a833de76f0b2d914c1b0d1f9d4836/6133c/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.jpg 1000w,\n/static/d15a833de76f0b2d914c1b0d1f9d4836/99fd6/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.jpg 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/d15a833de76f0b2d914c1b0d1f9d4836/7bf44/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.webp 500w,\n/static/d15a833de76f0b2d914c1b0d1f9d4836/61fb4/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.webp 1000w,\n/static/d15a833de76f0b2d914c1b0d1f9d4836/836c3/ca5e558d-e048-4e89-b0f0-a30995847180_72e44cb2-9179-4b00-ae87-bb58d975e19d_Neural%252BCoCulture.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 2000,
                    },
                  },
                },
              },
              modal_image: {
                url: null,
                localFile: null,
              },
              post_hover_text: {
                html: '<p>Co-culture of multiple neuronal cell types for enhanced neural network formation and activity</p>',
                text: 'Co-culture of multiple neuronal cell types for enhanced neural network formation and activity',
              },
              pre_hover_text: {
                html: '<p>Neural co-culture</p>',
                text: 'Neural co-culture',
              },
              read_more_text: {
                html: '<p>The use of <em>in vitro </em>neural cultures has greatly enhanced the understanding of the pathogenesis of neurodegenerative diseases and neural cancers. We are exploring the co-culture of multiple neuronal cell types to promote cell-cell interactions for enhanced neural network formation and activity that is more physiologically relevant.</p>',
                text: 'The use of in vitro neural cultures has greatly enhanced the understanding of the pathogenesis of neurodegenerative diseases and neural cancers. We are exploring the co-culture of multiple neuronal cell types to promote cell-cell interactions for enhanced neural network formation and activity that is more physiologically relevant.',
              },
            },
          ],
          primary: {
            title: {
              html: '<h3>Our models</h3>',
              text: 'Our models',
            },
          },
        },
      },
    },
  ],
};

export default data;
