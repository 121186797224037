const data = {
  name: 'Careers',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>Don’t see a role and think you’re a good fit for Inventia? Get in touch!<br />Email us at <a target="_blank" rel="noopener" href="mailto:careers@inventia.life">careers@inventia.life</a></p>',
              text: 'Don’t see a role and think you’re a good fit for Inventia? Get in touch!\nEmail us at careers@inventia.life',
            },
            title: {
              html: '<h3>Careers</h3>',
              text: 'Careers',
            },
          },
          items: [
            {
              content: {
                html: '<p>An exciting opportunity has become available at Inventia Life Science for a motivated <strong>NPI (New Product Introduction) Engineer in Sydney, Australia.</strong> We are a fast growing biotechnology company based in Sydney and are looking to take on a candidate who has experience in operationalisation and is keen to be involved in building an NPI function from scratch.</p>',
                text: 'An exciting opportunity has become available at Inventia Life Science for a motivated NPI (New Product Introduction) Engineer in Sydney, Australia. We are a fast growing biotechnology company based in Sydney and are looking to take on a candidate who has experience in operationalisation and is keen to be involved in building an NPI function from scratch.',
              },
              job_link: {
                url: 'https://inventia-life.cdn.prismic.io/inventia-life/232bfc3b-0c3a-4574-85b1-a78a11e17f19_NPI+Engineer.pdf',
              },
              job_link_text: {
                text: 'Learn more',
                html: '<p>Learn more</p>',
              },
              title: {
                html: '<p>NPI (New Product Introduction) Engineer in Sydney, Australia</p>',
                text: 'NPI (New Product Introduction) Engineer in Sydney, Australia',
              },
            },
            {
              content: {
                html: '<p>We are looking for <strong>Customer Success Managers</strong> located in Australia, Europe and the United States of America, to help us support our customers and provide the best possible RASTRUM experience. Get in contact if you think you would be a great fit for our team!</p>',
                text: 'We are looking for Customer Success Managers located in Australia, Europe and the United States of America, to help us support our customers and provide the best possible RASTRUM experience. Get in contact if you think you would be a great fit for our team!',
              },
              job_link: {
                url: 'https://www.linkedin.com/jobs/view/2722206709/?refId=74%2F9f%2FV%2Fkeglz7YCCNy%2BkA%3D%3D&trackingId=Vf2hZLqIPKbyhhDvQbDYpQ%3D%3D',
              },
              job_link_text: {
                text: 'Learn more',
                html: '<p>Learn more</p>',
              },
              title: {
                html: '<p>Customer Success Manager (AUS, EU, USA)</p>',
                text: 'Customer Success Manager (AUS, EU, USA)',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
