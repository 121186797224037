const data = {
  name: 'Info Columns',
  variations: [
    {
      title: 'White background',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>The power to place cell and matrix components in precise 3D constructs opens up new possibilities in fundamental explorative biology. For probing specific cell-cell or cell-matrix interactions, working in low or medium throughput, RASTRUM™ enables experiments not previously possible.</p><p><strong>Benefits —</strong></p>',
              text: 'The power to place cell and matrix components in precise 3D constructs opens up new possibilities in fundamental explorative biology. For probing specific cell-cell or cell-matrix interactions, working in low or medium throughput, RASTRUM™ enables experiments not previously possible. Benefits —',
            },
            grey_background: false,
            icon: {
              url: 'https://images.prismic.io/inventia-life/be4599db-88a1-46ff-9880-a98759cdbf56_0d1dbeb8-7d10-441a-bbed-e283e361beab_biology.svg?auto=compress,format',
            },
            title: {
              html: '<h2>Fundamental biology</h2>',
              text: 'Fundamental biology',
            },
          },
          items: [
            {
              dot_point: {
                html: '<p>Create complex 3D cell models with multiple cell and/or matrix components</p>',
                text: 'Create complex 3D cell models with multiple cell and/or matrix components',
              },
            },
            {
              dot_point: {
                html: '<p>Probe specific cell-cell or cell-matrix interactions in a controlled and quantitative way</p>',
                text: 'Probe specific cell-cell or cell-matrix interactions in a controlled and quantitative way',
              },
            },
            {
              dot_point: {
                html: '<p>Open your experimental design to rapid testing of multiple variables in 3D</p>',
                text: 'Open your experimental design to rapid testing of multiple variables in 3D',
              },
            },
          ],
        },
      },
    },
    {
      title: 'Grey background',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>The power to place cell and matrix components in precise 3D constructs opens up new possibilities in fundamental explorative biology. For probing specific cell-cell or cell-matrix interactions, working in low or medium throughput, RASTRUM™ enables experiments not previously possible.</p><p><strong>Benefits —</strong></p>',
              text: 'The power to place cell and matrix components in precise 3D constructs opens up new possibilities in fundamental explorative biology. For probing specific cell-cell or cell-matrix interactions, working in low or medium throughput, RASTRUM™ enables experiments not previously possible. Benefits —',
            },
            grey_background: true,
            icon: {
              url: 'https://images.prismic.io/inventia-life/be4599db-88a1-46ff-9880-a98759cdbf56_0d1dbeb8-7d10-441a-bbed-e283e361beab_biology.svg?auto=compress,format',
            },
            title: {
              html: '<h2>Fundamental biology</h2>',
              text: 'Fundamental biology',
            },
          },
          items: [
            {
              dot_point: {
                html: '<p>Create complex 3D cell models with multiple cell and/or matrix components</p>',
                text: 'Create complex 3D cell models with multiple cell and/or matrix components',
              },
            },
            {
              dot_point: {
                html: '<p>Probe specific cell-cell or cell-matrix interactions in a controlled and quantitative way</p>',
                text: 'Probe specific cell-cell or cell-matrix interactions in a controlled and quantitative way',
              },
            },
            {
              dot_point: {
                html: '<p>Open your experimental design to rapid testing of multiple variables in 3D</p>',
                text: 'Open your experimental design to rapid testing of multiple variables in 3D',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
