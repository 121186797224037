const data = {
  name: 'Roadmap',
  variations: [
    {
      title: '',
      props: {
        data: {
          items: [
            {
              content: {
                html: '<p>Select from our library of proprietary RASTRUM™ Matrices and 3D Cell Models, without having to waste time with 3D modelling or hydrogel engineering.</p>',
                text: 'Select from our library of proprietary RASTRUM™ Matrices and 3D Cell Models, without having to waste time with 3D modelling or hydrogel engineering.',
              },
              step_1: {
                html: '<p><strong>Choose a suitable matrix</strong></p><p>from our library of validated matrices</p>',
                text: 'Choose a suitable matrix from our library of validated matrices',
              },
              step_2: {
                html: '<p><strong>Pick the 3D cell model</strong></p><p>to suit your research question.</p>',
                text: 'Pick the 3D cell model to suit your research question.',
              },
              step_3: {
                html: '<p><strong>Define a platemap</strong></p><p>to suit your experimental workflow.</p>',
                text: 'Define a platemap to suit your experimental workflow.',
              },
              title: {
                html: '<h3>Select</h3>',
                text: 'Select',
              },
            },
            {
              content: {
                html: '<p>Incredibly easy and automated printing workflow to create your 3D Cell Model efficiently and reproducibly.</p>',
                text: 'Incredibly easy and automated printing workflow to create your 3D Cell Model efficiently and reproducibly.',
              },
              step_1: {
                html: '<p><strong>Auto-generated protocol</strong></p><p>lets you execute with confidence, with no need to tweak printing parameters.</p>',
                text: 'Auto-generated protocol lets you execute with confidence, with no need to tweak printing parameters.',
              },
              step_2: {
                html: '<p><strong>Plug and play printing</strong></p><p>allows you to resuspend your cell pellet in bioink, add it to the RASTRUM™ Cartridge and press play to print.</p>',
                text: 'Plug and play printing allows you to resuspend your cell pellet in bioink, add it to the RASTRUM™ Cartridge and press play to print.',
              },
              step_3: {
                html: '<p><strong>Automated cleaning</strong></p><p>and sterilisation pre and post-print means you can get back to work faster.</p>',
                text: 'Automated cleaning and sterilisation pre and post-print means you can get back to work faster.',
              },
              title: {
                html: '<h3>Print</h3>',
                text: 'Print',
              },
            },
            {
              content: {
                html: '<p>Printed 3D Cell Models are compatible with standard culture and downstream analysis workflows.</p>',
                text: 'Printed 3D Cell Models are compatible with standard culture and downstream analysis workflows.',
              },
              step_1: {
                html: '<p><strong>Incubate</strong></p><p>immediately post-print and cultivate with manual or automated media changes.</p>',
                text: 'Incubate immediately post-print and cultivate with manual or automated media changes.',
              },
              step_2: {
                html: '<p><strong>Interrogate</strong></p><p>your 3D culture by standard imaging or assay techniques - the hydrogel matrix won’t interfere.</p>',
                text: 'Interrogate your 3D culture by standard imaging or assay techniques - the hydrogel matrix won’t interfere.',
              },
              step_3: {
                html: '<p><strong>Cell extraction and analysis</strong></p><p>is possible by harvesting matured cell constructs from the printed 3D Cell Model.</p>',
                text: 'Cell extraction and analysis is possible by harvesting matured cell constructs from the printed 3D Cell Model.',
              },
              title: {
                html: '<h3>Use</h3>',
                text: 'Use',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
