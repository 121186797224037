const data = {
  name: 'Title and CTA',
  variations: [
    {
      title: 'Light',
      props: {
        data: {
          primary: {
            content: {
              html: '',
              text: '',
            },
            cta_link: {
              url: 'https://contact.inventia.life/',
            },
            cta_text: {
              text: 'Contact Sales',
              html: '<p>Contact Sales</p>',
            },
            dark_background: false,
            image: {
              url: null,
            },
            title: {
              html: '<h4>Ready to shift to better 3D models?</h4>',
              text: 'Ready to shift to better 3D models?',
            },
          },
        },
      },
    },
    {
      title: 'Dark',
      props: {
        data: {
          primary: {
            content: {
              html: '<p></p>',
              text: '',
            },
            cta_link: {
              url: 'https://contact.inventia.life/',
            },
            cta_text: {
              text: 'Get In Touch',
              html: '<p>Get In Touch</p>',
            },
            dark_background: true,
            image: {
              url: null,
            },
            title: {
              html: '<h6>Ready to discover the right matrix for your biology?</h6>',
              text: 'Ready to discover the right matrix for your biology?',
            },
          },
        },
      },
    },
    {
      title: 'With Image',
      props: {
        data: {
          primary: {
            content: {
              html: '<p>It is well established that 3D cell models better represent human tissues. They more accurately replicate biological processes, disease physiology and drug responses. The age of 2D cell culture is over.</p>',
              text: 'It is well established that 3D cell models better represent human tissues. They more accurately replicate biological processes, disease physiology and drug responses. The age of 2D cell culture is over.',
            },
            cta_link: {
              url: 'https://contact.inventia.life/',
            },
            cta_text: {
              text: 'Let us help you shift to better 3D models, faster',
              html: '<p>Let us help you shift to better 3D models, faster</p>',
            },
            dark_background: true,
            image: {
              url: 'https://images.prismic.io/inventia-life/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2056%2C1200&w=2000&h=1167',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMEBgf/xAAVAQEBAAAAAAAAAAAAAAAAAAAFBP/aAAwDAQACEAMQAAABxGTVHIIrFkB3/8QAGhAAAwADAQAAAAAAAAAAAAAAAQIDAAQSEf/aAAgBAQABBQLUbuND4KnqgYrjWZxn/8QAGREAAgMBAAAAAAAAAAAAAAAAAhEBAxAh/9oACAEDAQE/AYvEgS7n/8QAGREAAgMBAAAAAAAAAAAAAAAAAREAAgQS/9oACAECAQE/AaauapQllz//xAAaEAEBAAIDAAAAAAAAAAAAAAABABARIVGh/9oACAEBAAY/Ag8uiUuHUC7Mf//EABsQAQADAQADAAAAAAAAAAAAAAEAESExQVGx/9oACAEBAAE/IQC6Zb7LSsjfUPSCxi0R5GNSA4x7P//aAAwDAQACAAMAAAAQDy//xAAZEQEAAwEBAAAAAAAAAAAAAAABEUFhECH/2gAIAQMBAT8QkJBD0usTYef/xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAECAQE/EHF6yZl2/8QAHRABAAICAgMAAAAAAAAAAAAAAQAhEWExQVGRsf/aAAgBAQABPxAY0rIY1AZBFe1C377i+cI+YfNVKEhCRORRbt3BhG5//9k=',
                    },
                    images: {
                      fallback: {
                        src: '/static/d7cc6478a208acb2bd6fee1dce0c8fc6/0658a/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.jpg',
                        srcSet:
                          '/static/d7cc6478a208acb2bd6fee1dce0c8fc6/558ca/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.jpg 500w,\n/static/d7cc6478a208acb2bd6fee1dce0c8fc6/e50c7/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.jpg 1000w,\n/static/d7cc6478a208acb2bd6fee1dce0c8fc6/0658a/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.jpg 1999w',
                        sizes: '(min-width: 1999px) 1999px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/d7cc6478a208acb2bd6fee1dce0c8fc6/9ea6c/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.webp 500w,\n/static/d7cc6478a208acb2bd6fee1dce0c8fc6/510e7/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.webp 1000w,\n/static/d7cc6478a208acb2bd6fee1dce0c8fc6/ddd17/fc137f7d-081e-4855-a59b-313265c10544_4247d83e-7e93-4a48-a903-78787fdd187c_future.webp 1999w',
                          type: 'image/webp',
                          sizes: '(min-width: 1999px) 1999px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 1167.583791895948,
                  },
                },
              },
            },
            title: {
              html: '<h2>The future is 3D. Get there faster.</h2>',
              text: 'The future is 3D. Get there faster.',
            },
          },
        },
      },
    },
  ],
};

export default data;
