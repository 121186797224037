const data = {
  name: 'Image',
  variations: [
    {
      title: 'Half Half background',
      props: {
        data: {
          primary: {
            background: 'Half Half',
            image: {
              url: 'https://images.prismic.io/inventia-life/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2880%2C1620&w=2000&h=1125',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGAv/EABcBAAMBAAAAAAAAAAAAAAAAAAEDBQb/2gAMAwEAAhADEAAAAdUsI2ra5mRQk//EABgQAQEBAQEAAAAAAAAAAAAAAAMEAgES/9oACAEBAAEFAox0JD6YKeZ6sdbZPNK43ZW3aP/EABoRAAICAwAAAAAAAAAAAAAAAAACAyEBEjH/2gAIAQMBAT8BVY5E1Sjt4P/EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BtqH/xAAfEAACAQQCAwAAAAAAAAAAAAABAgASISIxEVEDMpH/2gAIAQEABj8Co8YVtMWNr9RyyquWNOpiKB0VnubzkPxVv7HzM//EABsQAQADAQEBAQAAAAAAAAAAAAEAESFBMVGB/9oACAEBAAE/IaR8ME+qeGTIMsFkcoOcf2KkqK5GZjyLHClTPfMtJqan/9oADAMBAAIAAwAAABDEP//EABoRAQACAwEAAAAAAAAAAAAAAAERQQAhcdH/2gAIAQMBAT8QUGw1UFiccLjDzzP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EAhqSDy//8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFBUWFx/9oACAEBAAE/EGAcuUHGEHbo/lmCp+iGk1qmIkEE+njwThLSHz1PEwhMITEVN6TnzmOy9IHi2J61M5Nws0TnM//Z',
                    },
                    images: {
                      fallback: {
                        src: '/static/3daddce926107b4b6ec0dd1a4d722080/c5387/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg',
                        srcSet:
                          '/static/3daddce926107b4b6ec0dd1a4d722080/933ed/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 500w,\n/static/3daddce926107b4b6ec0dd1a4d722080/ccd97/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 1000w,\n/static/3daddce926107b4b6ec0dd1a4d722080/c5387/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/3daddce926107b4b6ec0dd1a4d722080/86389/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 500w,\n/static/3daddce926107b4b6ec0dd1a4d722080/a5072/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 1000w,\n/static/3daddce926107b4b6ec0dd1a4d722080/46786/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 1125,
                  },
                },
              },
            },
          },
        },
      },
    },
    {
      title: 'White background',
      props: {
        data: {
          primary: {
            background: 'White',
            image: {
              url: 'https://images.prismic.io/inventia-life/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2880%2C1620&w=2000&h=1125',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGAv/EABcBAAMBAAAAAAAAAAAAAAAAAAEDBQb/2gAMAwEAAhADEAAAAdUsI2ra5mRQk//EABgQAQEBAQEAAAAAAAAAAAAAAAMEAgES/9oACAEBAAEFAox0JD6YKeZ6sdbZPNK43ZW3aP/EABoRAAICAwAAAAAAAAAAAAAAAAACAyEBEjH/2gAIAQMBAT8BVY5E1Sjt4P/EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BtqH/xAAfEAACAQQCAwAAAAAAAAAAAAABAgASISIxEVEDMpH/2gAIAQEABj8Co8YVtMWNr9RyyquWNOpiKB0VnubzkPxVv7HzM//EABsQAQADAQEBAQAAAAAAAAAAAAEAESFBMVGB/9oACAEBAAE/IaR8ME+qeGTIMsFkcoOcf2KkqK5GZjyLHClTPfMtJqan/9oADAMBAAIAAwAAABDEP//EABoRAQACAwEAAAAAAAAAAAAAAAERQQAhcdH/2gAIAQMBAT8QUGw1UFiccLjDzzP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EAhqSDy//8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFBUWFx/9oACAEBAAE/EGAcuUHGEHbo/lmCp+iGk1qmIkEE+njwThLSHz1PEwhMITEVN6TnzmOy9IHi2J61M5Nws0TnM//Z',
                    },
                    images: {
                      fallback: {
                        src: '/static/3daddce926107b4b6ec0dd1a4d722080/c5387/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg',
                        srcSet:
                          '/static/3daddce926107b4b6ec0dd1a4d722080/933ed/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 500w,\n/static/3daddce926107b4b6ec0dd1a4d722080/ccd97/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 1000w,\n/static/3daddce926107b4b6ec0dd1a4d722080/c5387/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/3daddce926107b4b6ec0dd1a4d722080/86389/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 500w,\n/static/3daddce926107b4b6ec0dd1a4d722080/a5072/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 1000w,\n/static/3daddce926107b4b6ec0dd1a4d722080/46786/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 1125,
                  },
                },
              },
            },
          },
        },
      },
    },
    {
      title: 'Black background',
      props: {
        data: {
          primary: {
            background: 'Black',
            image: {
              url: 'https://images.prismic.io/inventia-life/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2880%2C1620&w=2000&h=1125',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAUGAv/EABcBAAMBAAAAAAAAAAAAAAAAAAEDBQb/2gAMAwEAAhADEAAAAdUsI2ra5mRQk//EABgQAQEBAQEAAAAAAAAAAAAAAAMEAgES/9oACAEBAAEFAox0JD6YKeZ6sdbZPNK43ZW3aP/EABoRAAICAwAAAAAAAAAAAAAAAAACAyEBEjH/2gAIAQMBAT8BVY5E1Sjt4P/EABcRAQADAAAAAAAAAAAAAAAAAAABERL/2gAIAQIBAT8BtqH/xAAfEAACAQQCAwAAAAAAAAAAAAABAgASISIxEVEDMpH/2gAIAQEABj8Co8YVtMWNr9RyyquWNOpiKB0VnubzkPxVv7HzM//EABsQAQADAQEBAQAAAAAAAAAAAAEAESFBMVGB/9oACAEBAAE/IaR8ME+qeGTIMsFkcoOcf2KkqK5GZjyLHClTPfMtJqan/9oADAMBAAIAAwAAABDEP//EABoRAQACAwEAAAAAAAAAAAAAAAERQQAhcdH/2gAIAQMBAT8QUGw1UFiccLjDzzP/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEx/9oACAECAQE/EAhqSDy//8QAHBABAQADAAMBAAAAAAAAAAAAAREAITFBUWFx/9oACAEBAAE/EGAcuUHGEHbo/lmCp+iGk1qmIkEE+njwThLSHz1PEwhMITEVN6TnzmOy9IHi2J61M5Nws0TnM//Z',
                    },
                    images: {
                      fallback: {
                        src: '/static/3daddce926107b4b6ec0dd1a4d722080/c5387/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg',
                        srcSet:
                          '/static/3daddce926107b4b6ec0dd1a4d722080/933ed/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 500w,\n/static/3daddce926107b4b6ec0dd1a4d722080/ccd97/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 1000w,\n/static/3daddce926107b4b6ec0dd1a4d722080/c5387/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/3daddce926107b4b6ec0dd1a4d722080/86389/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 500w,\n/static/3daddce926107b4b6ec0dd1a4d722080/a5072/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 1000w,\n/static/3daddce926107b4b6ec0dd1a4d722080/46786/0a76b7b2-da85-4af7-8123-bd6fe817456f_f3ef9ad7-72aa-4c33-89a0-7abf9b03239b_matrix.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 1125,
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export default data;
