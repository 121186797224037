const data = {
  name: 'Vertical Carousel',
  variations: [
    {
      title: '',
      props: {
        data: {
          items: [
            {
              content: {
                html: '<p>At the core of Digital Bioprinting is our ability to print tiny droplets of cells and matrix components in a way that is very fast, very precise and gentle on cells. Check out this <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/af713177-3b66-4975-98a2-663c0cccca5f_20200415+-+Droplet+Control+App+Note+20200724.pdf">application note</a> for more detail on droplet control.</p>',
                text: 'At the core of Digital Bioprinting is our ability to print tiny droplets of cells and matrix components in a way that is very fast, very precise and gentle on cells. Check out this application note for more detail on droplet control.',
              },
              cta_link: {
                url: '',
              },
              cta_text: {
                html: '',
                text: '',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAwUlEQVQ4y+3TuQrCQBAGYJ89YGOZwsYyveAziEIaUQykUoQcHmySnX92cxgViXVSbASxyN9N8U0xxwhfZDTgP8H8SS/MnIlEihTdvgMTcNdYB3BDVKopjbAsUM6P5eIkSxhiZklSTzfFzJNdtB0TWCsRXuV4Wdnb7CKguLVDKyYudOKH2lo9bY8ON+QGGCiV2p8flvua7HJfUGGENSNIaieunZijtCnJaM+asyjNGtnjSAjNnBT3vW1C95qGf/41fgM2dIOR/r9DMwAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/ac4a3d5dfa26c608a4133e84cd945f53/49284/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.png',
                          srcSet:
                            '/static/ac4a3d5dfa26c608a4133e84cd945f53/2bf9a/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.png 500w,\n/static/ac4a3d5dfa26c608a4133e84cd945f53/bc400/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.png 1000w,\n/static/ac4a3d5dfa26c608a4133e84cd945f53/49284/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/ac4a3d5dfa26c608a4133e84cd945f53/5fbf5/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.webp 500w,\n/static/ac4a3d5dfa26c608a4133e84cd945f53/8dc52/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.webp 1000w,\n/static/ac4a3d5dfa26c608a4133e84cd945f53/e2d15/87529267-c187-4c6f-894d-b773f1a00e26_67e7e32a-23d6-4717-b0da-6c1c05f0d13c_droplet.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 2500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>Printing small droplets...</h4>',
                text: 'Printing small droplets...',
              },
            },
            {
              content: {
                html: '<p>We’ve developed bioink formulations that gel instantly on the combination of two separate droplets. In this way, cells are embedded in tunable matrix environments in a highly controllable way, without relying on light or temperature-induced gelation. Learn more about the importance of the printable matrix <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/3b232b7b-6030-42d5-845b-f3248f0fc4d8_20200415+-+Tunable+Matrix+App+Note+20200724.pdf">here</a>.</p>',
                text: 'We’ve developed bioink formulations that gel instantly on the combination of two separate droplets. In this way, cells are embedded in tunable matrix environments in a highly controllable way, without relying on light or temperature-induced gelation. Learn more about the importance of the printable matrix here.',
              },
              cta_link: {
                url: '',
              },
              cta_text: {
                html: '',
                text: '',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwklEQVQ4y+2UwU/bMBTG+dN3mTQuE2jTJm1ISAFN2tjKuuUAbSm0KnQp7SgpXptmLDRpaJzEcZ6fk/Y4pRsMiS4gceXJsr7LT5+f32cviQfU0iN8VTBf4mq/KfJhlCimElLERGKSCTHXd8GQkTyIWNWMKiZzaOSFmS4NI9fPeMh1Bim8HRK/OeFrXbZnBuVBrPRg/ZSqBDDXGYWAmRwXu7HSi4uDtHUpD234MoAPP3yVxInAvGMjRiPq1Q2sWrJhJ30fCWUlI9o1mGZxJxCIi2AAnCbut6G70sCCkeoU9UmsGv72WXR8ASfj4L0+edkM2pZI/3V+DQtIhPVO819oTOnKhi2bTrDWnrzSWGXI9kz3reasNNzNdoyw0Fl6rZ/+apNv6PLYxVOXFUiwpfPWhb8/9JTOeLlGD4xFzn9KIreoVx2I0jnULf7dZm0rqBj0KwlrJu+7f2/1f3OGmbQ/ddzVQ0/pxPvnYdkYvT4aPa/T7R7MEPNDIlJ5uUOcp9Xxs1r4mXjqmb188OtJefKxCxLvCAlKjBn3d/thocdHNKIhVQkt6vw+CZsPO8s2TrNgC4mZmCb3yPbtV3Wt4fEbejj8GzAdZh2zTDwpAAAAAElFTkSuQmCC',
                      },
                      images: {
                        fallback: {
                          src: '/static/df851a8b3db8978897de26328491331a/49284/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.png',
                          srcSet:
                            '/static/df851a8b3db8978897de26328491331a/2bf9a/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.png 500w,\n/static/df851a8b3db8978897de26328491331a/bc400/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.png 1000w,\n/static/df851a8b3db8978897de26328491331a/49284/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/df851a8b3db8978897de26328491331a/5fbf5/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.webp 500w,\n/static/df851a8b3db8978897de26328491331a/8dc52/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.webp 1000w,\n/static/df851a8b3db8978897de26328491331a/e2d15/8210d1c7-cb33-4c47-8141-4966d4681fe3_8b0f6421-944b-4ef8-aebb-abf72231338e_gel.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 2500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>that gel instantly...</h4>',
                text: 'that gel instantly...',
              },
            },
            {
              content: {
                html: '<p>By combining multiple matrix building blocks, with or without embedded cells, or cell suspensions alone, 3D cell models are built layer-by-layer. This exquisite control over cell and matrix environments enables <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/0d408fc8-a028-48ac-b87e-2be57e09fe96_20200309+-+Tumour+Spheroids+App+Note+20200724.pdf">3D cell models</a> not possible via manual methods.</p>',
                text: 'By combining multiple matrix building blocks, with or without embedded cells, or cell suspensions alone, 3D cell models are built layer-by-layer. This exquisite control over cell and matrix environments enables 3D cell models not possible via manual methods.',
              },
              cta_link: {
                url: '',
              },
              cta_text: {
                html: '',
                text: '',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsTAAALEwEAmpwYAAABKklEQVQ4y+3TT0vDUAwA8H5RP4CIFx2KhzIvogjCnAiCOBSZUhgTnTvY1j+dFhVUlA5dZ2VlsiGKtnlJ3+t6kt7scCp4EhdyzI+X5BGJ/SKkPv7jGBLB4McYEYNQ8FBwzkUowqgThJzhtxgYEflvXuOidl0+vSydnG1o58q+e2WDD0jYGwMgkffyepjdKqaWdTlfnljLDWaUoYWVgRkju02IXe8nMPGgmjduRwvOlNacNmjWtOQdY2zTkUtWquDu3bAAP84vdY17t6hZw4ozvttK6+20+jSpN2W1Jau1kaK9WmHJzhMYCeHZe9Sr97lKPaM/zB005o/cpWN73WybdfSAIX61bSSkSASCEyDzwPd8ZCzoCIw+WbjU449ZXBpnLMAH5kP/MP4rfgc9WWvP6fLcmwAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/2f9cdfb8a02441b3bec6a87e43cfb564/49284/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.png',
                          srcSet:
                            '/static/2f9cdfb8a02441b3bec6a87e43cfb564/2bf9a/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.png 500w,\n/static/2f9cdfb8a02441b3bec6a87e43cfb564/bc400/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.png 1000w,\n/static/2f9cdfb8a02441b3bec6a87e43cfb564/49284/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/2f9cdfb8a02441b3bec6a87e43cfb564/5fbf5/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.webp 500w,\n/static/2f9cdfb8a02441b3bec6a87e43cfb564/8dc52/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.webp 1000w,\n/static/2f9cdfb8a02441b3bec6a87e43cfb564/e2d15/8a77d83c-1c8b-4e1d-b47c-b00cea79371d_381a2119-a74b-4389-97a9-aaa466d2ec20_model.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 2500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>to build 3D cell models…</h4>',
                text: 'to build 3D cell models…',
              },
            },
            {
              content: {
                html: '<p>Whatever the cell model, our Digital Bioprinting technology enables unprecedented speed and reproducibility well-to-well, day-to-day and site-to-site. They can be produced quickly and reproducibly.</p>',
                text: 'Whatever the cell model, our Digital Bioprinting technology enables unprecedented speed and reproducibility well-to-well, day-to-day and site-to-site. They can be produced quickly and reproducibly.',
              },
              cta_link: {
                url: '',
              },
              cta_text: {
                html: '',
                text: '',
              },
              image: {
                url: 'https://images.prismic.io/inventia-life/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.png?auto=compress%2Cformat&rect=0%2C0%2C992%2C1240&w=2000&h=2500',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAIAAAC+dZmEAAAACXBIWXMAAAsTAAALEwEAmpwYAAABv0lEQVQ4y+2US2/aUBCF89uzSZZVpbRL4lUrJwrZ+Domdh4FN8aAnZIE8jIYcbENxvcxc39ABEg0qUxbKct2VlcafRqdM2fuFryjtv51WCkERIUKAPBVLVqoEFEpVQ4rpUY0cf3O7f0jIs4L1gq7fvgjnxeIePfw7PqdeDx5zf+EJYDXCoxd7fTImjL2MIzJ5wOyp/ejwYxzu1oju9qVHwgpS2BEvL7pGfvVC7teCBHTxNLJiU4GY8qkvHQaRuUo7N7BUkUJzDiPKE1nOSJKgFGajpJUykUrzecRpYxzLIVX/JxzCYDLNxeCCbFyTAIUb8lfDbt/GljE8fxQAiTZ9NxpnNl1mk0lgN+6tgy7/xiVGIYAXMrvzY65XakdkKwo+sOh+fGr+eFL7zmaMlY7PDG3K67X5kLipsk1w/H8YD353KlP0kwC+q1w4+S15uKtZv6Xmpdui4hO0rzE7ez3bi/2fNs3tONLu86EHNLEOjQtnQzHYybltzPX2K+GN73yPUuAKz8wdjR7lbA4Nj/pZE/vRdGM8dOqRXY0b5kw3JTtRrO9ynZeMD/oNoN1tp/cZntjtv94VYuWwv/f0DvgFw13W3nyoGb9AAAAAElFTkSuQmCC',
                      },
                      images: {
                        fallback: {
                          src: '/static/147e15c497b11384b486f841f62862a3/49284/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.png',
                          srcSet:
                            '/static/147e15c497b11384b486f841f62862a3/2bf9a/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.png 500w,\n/static/147e15c497b11384b486f841f62862a3/bc400/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.png 1000w,\n/static/147e15c497b11384b486f841f62862a3/49284/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/147e15c497b11384b486f841f62862a3/5fbf5/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.webp 500w,\n/static/147e15c497b11384b486f841f62862a3/8dc52/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.webp 1000w,\n/static/147e15c497b11384b486f841f62862a3/e2d15/a000ade5-36d7-4117-ae30-c97d2536920d_9b654b1a-97a9-4bd2-9360-7ca4e83c4431_reproduce.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 2500,
                    },
                  },
                },
              },
              title: {
                html: '<h4>efficiently and reproducibly.</h4>',
                text: 'efficiently and reproducibly.',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
