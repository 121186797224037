const data = {
  name: 'Full Width Image Text',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            button_type: true,
            content: {
              html: '<h5>Ready to shift to better 3D models?</h5>',
              text: 'Ready to shift to better 3D models?',
            },
            cta_link: {
              url: 'https://contact.inventia.life/',
            },
            cta_text: {
              html: '<p>Contact sales</p>',
              text: 'Contact sales',
            },
            image: {
              url: 'https://images.prismic.io/inventia-life/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.jpeg?auto=compress%2Cformat&rect=0%2C0%2C2158%2C1800&w=2000&h=1668',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'constrained',
                    placeholder: {
                      fallback:
                        'data:image/jpeg;base64,/9j/2wBDAAMCAgMCAgMDAwMEAwMEBQgFBQQEBQoHBwYIDAoMDAsKCwsNDhIQDQ4RDgsLEBYQERMUFRUVDA8XGBYUGBIUFRT/2wBDAQMEBAUEBQkFBQkUDQsNFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBQUFBT/wgARCAARABQDASIAAhEBAxEB/8QAGQABAQEAAwAAAAAAAAAAAAAAAAUGAQcI/8QAFgEBAQEAAAAAAAAAAAAAAAAAAwQF/9oADAMBAAIQAxAAAAHyxbk6qh6Tk+j15rQc+6LrP//EAB0QAAEDBQEAAAAAAAAAAAAAAAMAAQIEBREiMxP/2gAIAQEAAQUCd8qiXjE6FrK1028DBZlb+h+3/8QAGREAAgMBAAAAAAAAAAAAAAAAAAIDETIB/9oACAEDAQE/AYltilIdDa6f/8QAHBEAAgAHAAAAAAAAAAAAAAAAAAECAxARIjFB/9oACAECAQE/AYFdmPSVun//xAAlEAABBAACCwAAAAAAAAAAAAARAAECEgMQEyExQVFhgYKhscH/2gAIAQEABj8CU9TS2NV+Ks3ho/UbV5o4mLoIhxMpzQnflHuUunpf/8QAIRAAAQQBAwUAAAAAAAAAAAAAAQARIUExUaGxYXGBwfH/2gAIAQEAAT8hJkXOERJN+WHp+H6JsAHExtbA2iIRhplVyywM0kRR8ywtDQbGrluyGVufRbTjX//aAAwDAQACAAMAAAAQA9c+/8QAGhEAAgIDAAAAAAAAAAAAAAAAAAERsTGBof/aAAgBAwEBPxB0SE5S7MWrOw//xAAZEQADAAMAAAAAAAAAAAAAAAAAASERscH/2gAIAQIBAT8QcnA6qnDYj//EABwQAQACAwADAAAAAAAAAAAAAAERIQAxQRBhsf/aAAgBAQABPxBGWgJLwIDEqaSBIka5AX1cU4gToSk3j1jpW3FkKVUioo7vmi8ikpLoBlSstySGSwIFDZtQ0KrarrPoeX9P/9k=',
                    },
                    images: {
                      fallback: {
                        src: '/static/31b4f864876d36c6ff4bf45d964f600a/a72f8/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.jpg',
                        srcSet:
                          '/static/31b4f864876d36c6ff4bf45d964f600a/eb17c/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.jpg 500w,\n/static/31b4f864876d36c6ff4bf45d964f600a/c830a/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.jpg 1000w,\n/static/31b4f864876d36c6ff4bf45d964f600a/a72f8/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.jpg 2000w',
                        sizes: '(min-width: 2000px) 2000px, 100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/31b4f864876d36c6ff4bf45d964f600a/4daf0/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.webp 500w,\n/static/31b4f864876d36c6ff4bf45d964f600a/4e01c/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.webp 1000w,\n/static/31b4f864876d36c6ff4bf45d964f600a/ca3f9/451a1266-cba9-4fce-9b98-58bc8310ef23_523d1a23-f869-4224-9945-0ed33c954901_cta.webp 2000w',
                          type: 'image/webp',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                      ],
                    },
                    width: 2000,
                    height: 1667.9999999999998,
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export default data;
