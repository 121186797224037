const data = {
  name: 'Resources Accordion',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            title: {
              html: '<h2>Application Notes</h2>',
              text: 'Application Notes',
            },
          },
          items: [
            {
              content: {
                html: '<p>This <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/ed39b9af-fdbb-4f7c-b4d2-29bf02a03dad_001+Tumour+Spheroid.pdf">application note</a> provides an overview of the creation of breast and prostate multicellular tumour spheroids using RASTRUM™.</p>',
                text: 'This application note provides an overview of the creation of breast and prostate multicellular tumour spheroids using RASTRUM™.',
              },
              title: {
                html: '<p>High-Throughput Production of Matrix-Embedded Multicellular Tumour Spheroids</p>',
                text: 'High-Throughput Production of Matrix-Embedded Multicellular Tumour Spheroids',
              },
            },
            {
              content: {
                html: '<p>This <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/bf00c321-5c33-4631-a805-27a95aaa9180_006+Co+Culture+App+Note.pdf">application note</a> provides an overview of the creation of direct co-culture models using RASTRUM™.</p>',
                text: 'This application note provides an overview of the creation of direct co-culture models using RASTRUM™.',
              },
              title: {
                html: '<p>Generation of 3D Co-Culture Models Using the RASTRUM™ 3D Cell Culture Platform</p>',
                text: 'Generation of 3D Co-Culture Models Using the RASTRUM™ 3D Cell Culture Platform',
              },
            },
            {
              content: {
                html: '<p>This <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/4592ddd2-f89d-4362-a26e-d1507ee1728b_005+Cell+Retrieval.pdf">application note</a> demonstrates how cells encapsulated in RASTRUM™ Matrices can be easily retrieved to enable investigations into gene and protein expression.</p>',
                text: 'This application note demonstrates how cells encapsulated in RASTRUM™ Matrices can be easily retrieved to enable investigations into gene and protein expression.',
              },
              title: {
                html: '<p>Cell Retrieval from RASTRUM™ Matrices Enables High-Quality RNA and Protein Extraction</p>',
                text: 'Cell Retrieval from RASTRUM™ Matrices Enables High-Quality RNA and Protein Extraction',
              },
            },
            {
              content: {
                html: '<p>This <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/d74cb4dd-35b2-43b3-bf74-7bce66b48818_002+Imaging.pdf">application note</a> demonstrates the compatibility of imaging methods with 3D cell models created using RASTRUM™.</p>',
                text: 'This application note demonstrates the compatibility of imaging methods with 3D cell models created using RASTRUM™.',
              },
              title: {
                html: '<p>Brightfield and Fluorescent Imaging of RASTRUM™ 3D Cell Models</p>',
                text: 'Brightfield and Fluorescent Imaging of RASTRUM™ 3D Cell Models',
              },
            },
            {
              content: {
                html: '<p>This <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/1f65ea49-3a3e-4e62-ae4f-67217ad6ee70_004+Tunable+Matrix+Note.pdf">application note</a> provides an overview of the utility and tunability of RASTRUM™ matrix systems.</p>',
                text: 'This application note provides an overview of the utility and tunability of RASTRUM™ matrix systems.',
              },
              title: {
                html: '<p>Printable and Tunable 3D Cell Culture Environments using RASTRUM™ Matrices</p>',
                text: 'Printable and Tunable 3D Cell Culture Environments using RASTRUM™ Matrices',
              },
            },
            {
              content: {
                html: '<p>This <a target="_blank" rel="noopener" href="https://inventia.cdn.prismic.io/inventia/e7027eec-a6bb-4321-be05-e7acf16ecee8_003+Droplet+Control.pdf">application note</a> details the key printing features offered by RASTRUM™.</p>',
                text: 'This application note details the key printing features offered by RASTRUM™.',
              },
              title: {
                html: '<p>Drop-on-Demand Printing Technology of the RASTRUM™ 3D Cell Culture Platform</p>',
                text: 'Drop-on-Demand Printing Technology of the RASTRUM™ 3D Cell Culture Platform',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
