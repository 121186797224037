const data = {
  name: 'Logos Row',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            title: {
              html: '<h3>Our Investors</h3>',
              text: 'Our Investors',
            },
          },
          items: [
            {
              logo: {
                url: 'https://images.prismic.io/inventia-life/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%2BVentures%2BLogo.png?auto=compress%2Cformat&rect=0%2C0%2C1018%2C719&w=2000&h=1413',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADgElEQVQ4y41QW0zbdRg9vVC+X9vvX6iUjA0wjsYyYIibXIVtUEbKZYwBg164tCtMNopycWKRuElpNpi7ZC4Opi5DkwnMkZElOskkM8uiUbeEGH0wzsuDU7P4pA+aPfxMy1jQB+NJTvL9zvl9JycfhFDwgKoIifjhvKITcZSr36t3VvYixKpAECl4EPgvnf+h/9eflWELEfcRcTkRExGXErGDiCuJ+AARFxJxCRG3EHEnEecQsZuIO4i4gYiHiVi3OrCdiM8S8UEithDxfiIOEbGHiLcJoZwnYj8R9wihTOqF4hdCOUTEx4g4QMRjRJy0HLhc2SOEEhZCOcxsThRCCQihjAuh+FItqRY2xL1tMpr9JqM50vBUTKzBqxemV9gQP2bmhCajIS7IbEo2GBQgVqeHTks1MVoKqjTUq9bqOzU647NanTGo1XEf1JoXAOyAGrVQoV0dI8Ksj/MB6gYA1QA6AVUIgBrQARWnPoSttD5p81ZH+m8fTXNLrm2dlIj6tnjNhpbM8omrzsOXD+W6z3dmOi7NtYV/+G5w+t6ca+Sr8TL/9GvVvbP+LRVT7ob0TXe+rAF+lxIbq1sr6w8cOd4/euL90NT80vj8zW/CFxeXQu9e/fqDyRl5Y+i0vP7mrLx09Ky80DgkbwUm5C/Dc/LHwRk57wnLoKNpxm5PKY4W8V75Fk/s3IPkvIp160tqR4vbB260jU/92jX9qey7/rMcv7YkX1/4RN78/q78U0p5su3F+4PpNfcX3WPydu9bf2SttT75arMP2+2P4syoHcipdKkKm/chf/fe9fZnXtqeXeVx5tX737HvP3i5Y+LKZ/6T07daj134onnkjc/7J2dvHz9xTr5XNCAXi4blubLee8ba3JjIgdyl+ZrJEQewedcePFXfgU11PhS4uhFXWIeq4TMGztuhA6ApANQeQG2LHh0qe1eXc7TId+da0ZC8mNf3lz+tPKvbWokea1XEBwpcARS6e5Dv7FYVe5/XtE99jFxnD1K2NUZ9G4AkLENKCbdrHzbEJltfzmy8G9rYJFseKw2Eslw4mt2qTuREPERG2S5klNbBVlKNopbn8HRHEPXdQXj3dsPTGUCTtwtb/W70tQ9oW5PykRa/xppjSdsZr7eYgEQsFA/BbHgE/xsZybZow4XCfoxk71ZZzWuQl/g4UpUUNKytwU+O00gwJuBvLxQC8KO8W/wAAAAASUVORK5CYII=',
                      },
                      images: {
                        fallback: {
                          src: '/static/d93d0c59af63135ccccd46ea7d5f56d0/6896d/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.png',
                          srcSet:
                            '/static/d93d0c59af63135ccccd46ea7d5f56d0/85072/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.png 500w,\n/static/d93d0c59af63135ccccd46ea7d5f56d0/1f1a5/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.png 1000w,\n/static/d93d0c59af63135ccccd46ea7d5f56d0/6896d/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/d93d0c59af63135ccccd46ea7d5f56d0/319a4/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.webp 500w,\n/static/d93d0c59af63135ccccd46ea7d5f56d0/6f8c1/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.webp 1000w,\n/static/d93d0c59af63135ccccd46ea7d5f56d0/4db63/068ba46b-7a75-4699-ac4c-d948cf2e0537_228ef4ef-cb2d-4a7f-86ee-8affc214b058_Blackbird%252BVentures%252BLogo.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 1413,
                    },
                  },
                },
              },
            },
            {
              logo: {
                url: 'https://images.prismic.io/inventia-life/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%2BLOGO%2B1.png?auto=compress%2Cformat&rect=1%2C0%2C1258%2C241&w=2000&h=383',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAABE0lEQVQY0y2PsUpDQRREVwKClSDuoCDoXmzCfeobUKy0CVoYEUQhIqhNFNTGQm0srLSIlZUQ7BSSXUglBP0EO5vEtx/hP8iGFAMzUxxmTOG1VHg1hdeJwutt4XW118quL44WzfR8vgdhF8JjCBvW5bsQPkLYscIZ62issAThCCT53CSQ6bd1tPDajUHfYtCffjurvt4vmLlyXoawBuEmhKcQViBch/DMOo5jNjfW5SbBICwNgL9tHSu8fsWgnRj0Owat/n2qMWbFTDouQ7hvHQ+tG8DS0i0I1yC8hOMOHM/heAJhelNLC99jyJox6EcMuh2DTvVaWb1+sJQub0DYhPAZwhsIXyC8g/ABwqdh1xjqKuV/ldpaQyA0P2IAAAAASUVORK5CYII=',
                      },
                      images: {
                        fallback: {
                          src: '/static/fbc62d00909e6b7aa10963ad53bdf3a2/e6633/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.png',
                          srcSet:
                            '/static/fbc62d00909e6b7aa10963ad53bdf3a2/e3c30/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.png 500w,\n/static/fbc62d00909e6b7aa10963ad53bdf3a2/d298a/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.png 1000w,\n/static/fbc62d00909e6b7aa10963ad53bdf3a2/e6633/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.png 1999w',
                          sizes: '(min-width: 1999px) 1999px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/fbc62d00909e6b7aa10963ad53bdf3a2/dfd9d/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.webp 500w,\n/static/fbc62d00909e6b7aa10963ad53bdf3a2/07b49/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.webp 1000w,\n/static/fbc62d00909e6b7aa10963ad53bdf3a2/77bc9/5d7ae7a5-d2f6-4757-9e81-63729ae385f7_053b5094-b150-4ebf-9b4d-3ac74c0a779a_MSEQ%252BLOGO%252B1.webp 1999w',
                            type: 'image/webp',
                            sizes: '(min-width: 1999px) 1999px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 383.19159579789897,
                    },
                  },
                },
              },
            },
            {
              logo: {
                url: 'https://images.prismic.io/inventia-life/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.png?auto=compress%2Cformat&rect=0%2C0%2C288%2C55&w=2000&h=382',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAzklEQVQY0y2QTyuEcRSF328gazvlWdhYyEJpHsKkmISFiFKzGH9WiGJmfucXZc2HUD6mZt53cet2bp1znttgHjF3WA6wHGNGmCnmDOsipmLpd/oe5hbzgGWIKZiTbm4wuw3mHrOB+cWyhhm3ARli3cIM6E0WupDrVp+XGGMRs4q5wLxivZwZ7mCeMX+YmcET5odeWca6junjdAXzgXnDfGGOMFdYBphNzEtLUoczw/eu+mGXNOn2BusSJphPzGmHO2pvOceyjdlv3zNH/v4HB6Jjifmu5nYAAAAASUVORK5CYII=',
                      },
                      images: {
                        fallback: {
                          src: '/static/7b1887097e546da39805d5d78e4b0c5e/c0cf2/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.png',
                          srcSet:
                            '/static/7b1887097e546da39805d5d78e4b0c5e/e3c30/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.png 500w,\n/static/7b1887097e546da39805d5d78e4b0c5e/d0598/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.png 1000w,\n/static/7b1887097e546da39805d5d78e4b0c5e/c0cf2/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/7b1887097e546da39805d5d78e4b0c5e/dfd9d/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.webp 500w,\n/static/7b1887097e546da39805d5d78e4b0c5e/f3f64/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.webp 1000w,\n/static/7b1887097e546da39805d5d78e4b0c5e/b7418/2fba27bb-a480-44ab-88a7-aaf78f4ce8aa_213241bc-6b3c-4a0a-aa26-534a40491d62_skip.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 382,
                    },
                  },
                },
              },
            },
            {
              logo: {
                url: 'https://images.prismic.io/inventia-life/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.png?auto=compress%2Cformat&rect=0%2C0%2C228%2C98&w=2000&h=860',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAYAAAAywQxIAAAACXBIWXMAAAsTAAALEwEAmpwYAAACeElEQVQozzVQ2UtUcRid/6GUNJe3oDCXKNMWwZLUNomyUtO2wXLXtnFDFMUlVNKyXlLLRkvtLTNc0fDBrZF80RbJ0EzH+Y2jozZzvfecuDf64OM7fOf3/Tjn6C6ev2CJOn3Gcjgo2HLubJSoKCsXz+rqRNOLl+JafII19GiI1WQyif6+PmEVQpDUuuNdh1AURXR3dWmcupNlWegsFgvta2vMTM9gd1cX21pb6b/XlyeOh/FDZyedTifNS2bGXY7h2/Z2qmU2mxkaEsIVq5WJej2vJ1zV9rIsU0cSahcXFWFkeBijIyNwc3HFw/JyJN+6jd6eHo0/FR6BRL1ew8amJgQdCMTjmhpUV1WhsKAAn8bHNU5nuHcf8bFxPBJ8CNfiE5Cekoo7mZkYGx2lqj4lKQkZqWmsrqxEaUkJOjveo7iwiM1GIzzd3DE4MIDW12+Y88Cgfkjd4uIir8TEYnp6mhMmE3Ozs9VjqurUB5IkYcd2FzTU13N+fp4+u/egxdjMqakpBvj6MT8nl3ezslQHXPi1oFlmZlo6Z77PqBAVZeU4GRGBS9HRyDYYkJ+XBz8fH/T2dGuW0lKSYV5axJPaWrQYjfgfWVNjIx5VVUHLcG7uJyXJoSnasK9yYmyciiTzY98AhwaH+HvBzHW7Qwve6VS0+fXbDzq2/mG1/sgyZ2ZnqVsR69ywS1hZ2eSybRNWh4INAsK5CgdlbFHCFlchwwpZXgYhgC112sENAdiWoQgLKATocEAX5l/KYwGlOBhYRu8b7dj5dBLuzwvh8SoSrm2RCO/fj4ZJL3ye9YRt2ROSzQPyF08ozR5AvhcQ7Q3s2wVsc4NyU4+/J9ggq6yIbyUAAAAASUVORK5CYII=',
                      },
                      images: {
                        fallback: {
                          src: '/static/a1f0a23dd95bdaff829e471568104d59/509d0/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.png',
                          srcSet:
                            '/static/a1f0a23dd95bdaff829e471568104d59/19534/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.png 500w,\n/static/a1f0a23dd95bdaff829e471568104d59/bbb24/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.png 1000w,\n/static/a1f0a23dd95bdaff829e471568104d59/509d0/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/a1f0a23dd95bdaff829e471568104d59/7fe3c/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.webp 500w,\n/static/a1f0a23dd95bdaff829e471568104d59/11a0a/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.webp 1000w,\n/static/a1f0a23dd95bdaff829e471568104d59/00238/c7a1411b-8249-4cd6-a124-73033c14fe40_8a3a7d08-2eb0-4ea9-8306-a911bfc3344d_prisma.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 860,
                    },
                  },
                },
              },
            },
            {
              logo: {
                url: 'https://images.prismic.io/inventia-life/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.png?auto=compress%2Cformat&rect=0%2C0%2C288%2C90&w=2000&h=625',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'constrained',
                      placeholder: {
                        fallback:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABHklEQVQY0z2RvUsDQRDFNypCjIIIgiIIgYCgIM7vqrMxCOJXBIkpjIKmstFKG+3SivZWZmYvIvb+gzLZO4tZ3szbN59BROtgl2B3oCsiGsDcdsA2RWwRbBkm8QOwAcSt5Fdm/9ifBxdC3AW9BmuV5EvitAnWATsBzUEfQdtgR2A5WB90Fewc7NCFN2D7EDugA9BZ0A2wd9Ah6BXYEOxM5HMO9AK0L6I9sFeI92AfEJ/B3jyhpZFjF/QHrOHVRbQpouugT4mf+POgx6kBXQPtgnnBPbBbsF6Ake9gG+Kpj5rnvwGKaZFRcINiBooaxFqWfQUYO55Ka3FsS2mHcQGs7oKQZbGRZUXLsQshhpTQRdGF5b/vEsfyEOPqgLUq9gf8MY9mAfZi4gAAAABJRU5ErkJggg==',
                      },
                      images: {
                        fallback: {
                          src: '/static/2ef69c9bcf0c625954a982a3fa98639e/22f65/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.png',
                          srcSet:
                            '/static/2ef69c9bcf0c625954a982a3fa98639e/56378/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.png 500w,\n/static/2ef69c9bcf0c625954a982a3fa98639e/821dd/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.png 1000w,\n/static/2ef69c9bcf0c625954a982a3fa98639e/22f65/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.png 2000w',
                          sizes: '(min-width: 2000px) 2000px, 100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/2ef69c9bcf0c625954a982a3fa98639e/a04e9/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.webp 500w,\n/static/2ef69c9bcf0c625954a982a3fa98639e/72992/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.webp 1000w,\n/static/2ef69c9bcf0c625954a982a3fa98639e/1a729/904ab70d-6e31-442c-a2f6-9161ebf1f066_df1177f6-7599-43d6-9ebe-999e75c36ed8_airtree.webp 2000w',
                            type: 'image/webp',
                            sizes: '(min-width: 2000px) 2000px, 100vw',
                          },
                        ],
                      },
                      width: 2000,
                      height: 625,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
