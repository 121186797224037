const data = {
  name: 'Feature Cards',
  variations: [
    {
      title: '',
      props: {
        data: {
          primary: {
            title: {
              html: '<h2></h2>',
              text: '',
            },
          },
          items: [
            {
              content: {
                html: '<p>The creation of 3D Cell Models can be complex and fiddly. Working with RASTRUM™ is simple. Automated and pre-validated workflows take away the pain of working in 3D. </p>',
                text: 'The creation of 3D Cell Models can be complex and fiddly. Working with RASTRUM™ is simple. Automated and pre-validated workflows take away the pain of working in 3D. ',
              },
              icon: {
                url: 'https://images.prismic.io/inventia-life/c7f3b9e8-b005-4de8-ad10-408c59369577_34450cd2-11d5-4e8d-82cc-f9157e03f3ec_simple.svg?auto=compress,format',
              },
              title: {
                html: '<h4>Simple</h4>',
                text: 'Simple',
              },
            },
            {
              content: {
                html: '<p>Our digital bioprinting technology is quick. Create 3D Cell Models an order of magnitude faster than manual methods, freeing up valuable time.</p>',
                text: 'Our digital bioprinting technology is quick. Create 3D Cell Models an order of magnitude faster than manual methods, freeing up valuable time.',
              },
              icon: {
                url: 'https://images.prismic.io/inventia-life/9114c95e-b07a-4430-a356-b298cd6e9845_3fd0a4a2-0bdf-4411-a7e8-3672c070e066_efficient.svg?auto=compress,format',
              },
              title: {
                html: '<h4>Efficient</h4>',
                text: 'Efficient',
              },
            },
            {
              content: {
                html: '<p>Scientific reproducibility is paramount to translational success. RASTRUM™ delivers unprecedented reproducibility well-to-well, day-to-day and site-to-site. </p>',
                text: 'Scientific reproducibility is paramount to translational success. RASTRUM™ delivers unprecedented reproducibility well-to-well, day-to-day and site-to-site. ',
              },
              icon: {
                url: 'https://images.prismic.io/inventia-life/2e1a9387-cec2-4167-93b8-8f5a11b8f57b_d316af68-a64e-431f-b2ad-848302bf6e4b_reproducible.svg?auto=compress,format',
              },
              title: {
                html: '<h4>Reproducible</h4>',
                text: 'Reproducible',
              },
            },
            {
              content: {
                html: '<p>The power of digital bioprinting gives you the capability to create 3D Cell Models that are not possible with manual methods. </p>',
                text: 'The power of digital bioprinting gives you the capability to create 3D Cell Models that are not possible with manual methods. ',
              },
              icon: {
                url: 'https://images.prismic.io/inventia-life/6863fa40-4344-4ab9-809e-f42fbbd25aef_0b836c1d-b933-4ef1-8c93-9c9ca2f2f170_powerful.svg?auto=compress,format',
              },
              title: {
                html: '<h4>Powerful</h4>',
                text: 'Powerful',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
