const data = {
  name: 'Dot Points',
  variations: [
    {
      title: 'dark background',
      props: {
        data: {
          primary: {
            dark_background: true,
            title: {
              html: '',
              text: '',
            },
          },
          items: [
            {
              dot_point: {
                html: '<p>Compact form factor for benchtop use</p>',
                text: 'Compact form factor for benchtop use',
              },
            },
            {
              dot_point: {
                html: '<p>Integrated clean chamber with dual HEPA filtration</p>',
                text: 'Integrated clean chamber with dual HEPA filtration',
              },
            },
            {
              dot_point: {
                html: '<p>Patented RASTRUM™ printhead rapidly deposits up to 8 components simultaneously</p>',
                text: 'Patented RASTRUM™ printhead rapidly deposits up to 8 components simultaneously',
              },
            },
            {
              dot_point: {
                html: '<p>Low volume handling with negligible loss of precious samples</p>',
                text: 'Low volume handling with negligible loss of precious samples',
              },
            },
          ],
        },
      },
    },
    {
      title: 'light background',
      props: {
        data: {
          primary: {
            dark_background: false,
            title: {
              html: '',
              text: '',
            },
          },
          items: [
            {
              dot_point: {
                html: '<p>Compact form factor for benchtop use</p>',
                text: 'Compact form factor for benchtop use',
              },
            },
            {
              dot_point: {
                html: '<p>Integrated clean chamber with dual HEPA filtration</p>',
                text: 'Integrated clean chamber with dual HEPA filtration',
              },
            },
            {
              dot_point: {
                html: '<p>Patented RASTRUM™ printhead rapidly deposits up to 8 components simultaneously</p>',
                text: 'Patented RASTRUM™ printhead rapidly deposits up to 8 components simultaneously',
              },
            },
            {
              dot_point: {
                html: '<p>Low volume handling with negligible loss of precious samples</p>',
                text: 'Low volume handling with negligible loss of precious samples',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
